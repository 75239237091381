export const componentTypes = {
  alerts: "alerts",
  insights: "insights",
  tactics: "Attack by Tactics",
  notification: "notifications",
};

export const findingsMap = {
  "Unauthorized Activity": "unauth_activity",
  "Malware": "file", //malware
  "Command & Control": "network", // c&c
  "Suspicious Communications": "suspicious_communications", //
  "Misconfigurations": "misconfigs", //misconfigurations
  "Secrets - Storage": "secrets_storage", // ecr secrets
  "Secrets - Containers": "secrets_containers", // ecr secrets
  "Secrets - Infrastructure": "secrets_infra", // misconfigs - secrets
  "User Activity Insights": "user_activity", //ueba
  "Cryptojacking": "cryptojackings", 
  "all":"all", // get everything for overview.
}

// flips k-v pairs.
export const findingsMapInverted = Object.keys(findingsMap).reduce((returnObj, key) => {
    returnObj[findingsMap[key]] = key;
    return returnObj;
}, {});
