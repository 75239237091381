export const severityNumberToStringMap = {
	"0": "-",
	"1": "Low",
	"3": "Medium",
	"5": "High",
	"7": "Critical"
}

export const severityStringToNumberMap = {
    "-": 0,
    "Low": 1,
    "Medium": 3,
    "High": 5,
    "Critical": 7
}

export const TABLE_TYPES = {
    NETWORK_INFERENCE: 'NETWORK_INFERENCE',
    CONN_LOGS: 'CONN_LOGS',
    SSL_LOGS: 'SSL_LOGS',
    DNS_LOGS: 'DNS_LOGS',
    RDP_LOGS: 'RDP_LOGS',
    RPC_LOGS: 'RPC_LOGS',
    SMB_LOGS: 'SMB_LOGS',
    SMB_FILE_LOGS: 'SMB_FILE_LOGS',
    SSH_LOGS: 'SSH_LOGS',
    NTLM_LOGS: 'NTLM_LOGS',
    DHCP_LOGS: 'DHCP_LOGS',
    KERBEROS_LOGS: 'KERBEROS_LOGS',
    HTTP_LOGS: 'HTTP_LOGS',
    CLOUDTRAIL_LOGS: 'CLOUDTRAIL_LOGS',
    AZURE_ACTIVITY_LOGS: 'AZURE_ACTIVITY_LOGS',
    ALERTS: 'ALERTS',
    NOTIFICATIONS: 'NOTIFICATIONS'
}

// Does the table show up at all for this cloud?
export const TABLE_CLOUDS = {
  NETWORK_INFERENCE: ["premise", "aws", "azure",  "gcp", "oci"],
  CONN_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  SSL_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  DNS_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  RDP_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  RPC_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  SMB_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  SMB_FILE_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  SSH_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  NTLM_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  DHCP_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  KERBEROS_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  HTTP_LOGS: ["premise", "aws", "azure",  "gcp", "oci"],
  CLOUDTRAIL_LOGS: ["aws"],
  AZURE_ACTIVITY_LOGS: ["azure"],
  ALERTS: ["premise", "aws", "azure",  "gcp", "oci"],
  NOTIFICATIONS: ["premise", "aws", "azure",  "gcp", "oci"],
} 

export const SEARCH_TYPES = {
    NETWORK_INFERENCE: 'network_inference',
    CONN_LOGS: 'conn_logs',
    SSL_LOGS: 'ssl_logs',
    DNS_LOGS: 'dns_logs',
    RDP_LOGS: 'rdp_logs',
    RPC_LOGS: 'dce_rpc_logs',
    SMB_LOGS: 'smb_mapping_logs',
    SMB_FILE_LOGS: 'smb_files_logs',
    SSH_LOGS: 'ssh_logs',
    NTLM_LOGS: 'ntlm_logs',
    DHCP_LOGS: 'dhcp_logs',
    KERBEROS_LOGS: 'kerberos_logs',
    HTTP_LOGS: 'http_logs',
    CLOUDTRAIL_LOGS: 'cloudtrail_logs',
    AZURE_ACTIVITY_LOGS: 'azure_activity_logs',
    ALERTS: 'alerts',
    NOTIFICATIONS: 'notifications',
}

// flips k-v pairs.
export const SEARCH_TYPES_INVERTED = Object.keys(SEARCH_TYPES).reduce((returnObj, key) => {
    returnObj[SEARCH_TYPES[key]] = key;
    return returnObj;
}, {});

export const INVESTIGATION_TYPES = SEARCH_TYPES;

export const TABLE_TITLES = {
	NETWORK_INFERENCE: 'File Analytics',
  CONN_LOGS: 'Connection Outliers',
  SSL_LOGS: 'TLS Compliance',
  DNS_LOGS: 'DNS Compliance',
  RDP_LOGS: 'RDP',
  RPC_LOGS: 'RPC',
  SMB_LOGS: 'SMB Mapping',
  SMB_FILE_LOGS: 'SMB Files',
  SSH_LOGS: 'SSH',
  NTLM_LOGS: 'NTLM',
  DHCP_LOGS: 'DHCP',
  KERBEROS_LOGS: 'Kerberos',
  HTTP_LOGS: 'HTTP',
  CLOUDTRAIL_LOGS: 'CloudTrail',
  AZURE_ACTIVITY_LOGS: 'Azure Activity',
  ALERTS: 'Triage Alerts',
  NOTIFICATIONS: 'Notifications',
}

// Don't have time to fix right now - some parts of the UI use this in 'key order' and not the value of the key.
export const TABLE_ORDER = {
    NETWORK_INFERENCE: 1,
    CONN_LOGS: 3,
    SSL_LOGS: 2,
    DNS_LOGS: 4,
    RDP_LOGS: 5,
    RPC_LOGS: 6,
    SMB_LOGS: 7,
    SMB_FILE_LOGS: 8,
    SSH_LOGS: 9,
    NTLM_LOGS: 10,
    KERBEROS_LOGS: 11,
    HTTP_LOGS: 12,
    DHCP_LOGS: 13,
    NOTIFICATIONS: 14,
    CLOUDTRAIL_LOGS: 15,
    AZURE_ACTIVITY_LOGS: 16,
    
}
export const reverseStageHash = {
    "1": "Reconnaissance",
    "2": "Weaponization",
    "3": "Delivery",
    "4": "Exploitation",
    "5": "Installation",
    "6": "Command & Control",
    "7": "Action on Objectives"
};
  
/*export const INVESTIGATION_TYPE_TITLES = {
	NETWORK_INFERENCE: 'Search Query',
    CONN_LOGS: 'Connection',
    SSL_LOGS: 'TLS',
    DNS_LOGS: 'DNS',
    RDP_LOGS: 'RDP',
    RPC_LOGS: 'RPC',
    SMB_LOGS: 'SMB',
    SMB_FILE_LOGS: 'SMB Files',
    SSH_LOGS: 'SSH',
    NTLM_LOGS: 'NTLM',
    DHCP_LOGS: 'DHCP',
    KERBEROS_LOGS: 'Kerberos',
    HTTP_LOGS: 'HTTP',
    CLOUDTRAIL_LOGS: 'CloudTrail',
    ALERTS: 'Alerts',
    NOTIFICATIONS: 'Notifications',
}*/

export const INVESTIGATION_TYPE_TITLES = TABLE_TITLES;

export const INVESTIGATION_STATUSES = {
    NEW: "New",
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    ARCHIVED: "Archived",
}

export const WORKBENCH_TABS = {
    DATA: "Data",
    INSIGHTS: "Insights",
    FINDINGS: "Findings",
}

export const FINDINGS_TYPES = [
    "Suspicious Communications",
    "Malware",
    "Command & Control", 
    "Cryptojacking", 
    "Unauthorized Activity",
    "Misconfigurations", 
    "Secrets - Storage",
    "Secrets - Containers",
    "Secrets - Infrastructure",
    "User Activity Insights", 
]

export const ROLE_MAP = {
    ADMIN: 3,
    ANALYST: 2,
    USER: 1,
    UNKNOWN: 0,
}
