import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchThreatOverview } from "../../../api/triageAlertsAPI";

export const getAlerts = createAsyncThunk(
  "alertsData",
  async (urlParams) => {
    const { cloud } = urlParams;
    const response = await fetchThreatOverview(cloud);
    return response;
  }
);

const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    refresh: 0,
  },

  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getAlerts.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAlerts.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getAlerts.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    },
  },
});

export const { updateRefresh } = alertsSlice.actions;
export default alertsSlice.reducer;
