import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchInvestigationDetails, deleteInvestigationDetail } from "../../../../api/investigationAPI";

export const getInvestigationDetails = createAsyncThunk(
  "workbenchTable",
  async ( investigationId ) => {
    const response = await fetchInvestigationDetails(investigationId);
    return response;
  }
);

export const removeInvestigationDetail = createAsyncThunk(
  "removeDetails",
  async ( detailsObj ) => {
    const response = await deleteInvestigationDetail(detailsObj);
    return response;
  }
);

const workbenchAlertsTableSlice = createSlice({
  name: "workbenchDetails",
  initialState: {
    isLoading: false,
    error: null,
    data: [],
    selectedInvestigationId:null,
    isInvestigationDetailsStale:true,
  },
  reducers: {
    changeSelectedInvestigationId(state, action) {
      const { selectedInvestigationId } = action.payload;
      state.selectedInvestigationId = selectedInvestigationId;
    },
    setInvestigationDetailsStale(state, action) {
      state.isInvestigationDetailsStale = true;
    }
  },
  extraReducers: {
    [getInvestigationDetails.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getInvestigationDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isInvestigationDetailsStale = false;
    },
    [getInvestigationDetails.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
      state.isInvestigationDetailsStale = false;
    },
    [removeInvestigationDetail.fulfilled]: (state, action) => {
      state.isInvestigationDetailsStale = true;
    },
    [removeInvestigationDetail.rejected]: (state, action) => {
      state.isInvestigationDetailsStale = false;
      state.error = action.error.message;
    },
  },
});

export const { changeSelectedInvestigationId, setInvestigationDetailsStale } = workbenchAlertsTableSlice.actions;
export default workbenchAlertsTableSlice.reducer;
