import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSystemStatusTableData, getDatadogDataAPI } from "../../../api/systemStatusAPI";

export const getDatadogData = createAsyncThunk(
  "systemStatusDog",
  async (arbitraryString) => {
    const response = await getDatadogDataAPI(arbitraryString);
    return response;
  }
);

export const getSystemStatusTableData = createAsyncThunk(
  "systemStatusTable",
  async () => {
    const response = await fetchSystemStatusTableData();
    if(response?.systemDevices){
      response.systemDevices.forEach(a=>{
        if(a.devicePorts){
          a.devicePorts.forEach(b=>{
            if(b.key) a[b.key] = b;
          })
        }
      })
    }
    return response;
  }
);

const systemStatusTableSlice = createSlice({
  name: "systemStatusTable",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    page: 1,
    size: 10,
    refresh: 0,
  },
  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getSystemStatusTableData.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getSystemStatusTableData.fulfilled]: (state, action) => {
      state.data = action.payload?.systemDevices;
      state.isLoading = false;
    },
    [getSystemStatusTableData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export const { updateRefresh } = systemStatusTableSlice.actions;
export default systemStatusTableSlice.reducer;
