import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchFindingsOverview } from "../../../api/triageAlertsAPI";

export const getFindingsOverview = createAsyncThunk(
  "findingOverview",
  async (urlParams) => {
    const { query, findingType, cloud, filters } = urlParams;
    const response = await fetchFindingsOverview(cloud, query, findingType, filters);
    return response;
  }
);

const findingsSlice = createSlice({
  name: "findings",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    hardenTypes: ["Misconfigurations", "Secrets - Storage", "Secrets - Containers", "Secrets - Infrastructure"],
    defendTypes: ["Malware", "Command & Control", "Suspicious Communications", "Cryptojacking", "Unauthorized Activity", "User Activity Insights"],
    misconfigFilters: { "account_id": [], "region": [] },
    selectedHardenType: "Misconfigurations",
    selectedDefendType: "Malware",
    activeType: null,
    activeGraph: null,
    isMisconfig: false, // misconfig is a separate table, so it isn't easy to differentiate w/ 'activeType'
    refresh: 0,
  },
  reducers: {
    changeHardenFindingType(state, action) {
      const findingType = action.payload;
      state.selectedHardenType = findingType;
    },
    changeDefendFindingType(state, action) {
      const findingType = action.payload;
      state.selectedDefendType = findingType;
    },
    changeActiveFindingType(state, action) {
      const findingType = action.payload;
      state.activeType = findingType;
    },
    changeActiveFindingGraph(state, action) {
      const graphType = action.payload;
      state.activeGraph = graphType;
    },
    updateRefresh(state) {
      state.refresh++;
    },
    changeMisconfigFilters(state, action) {
      state.misconfigFilters = action.payload;
    },
    changeIsMisconfig(state, action) {
      state.isMisconfig = action.payload;
    }
  },
  extraReducers: {
    [getFindingsOverview.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getFindingsOverview.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getFindingsOverview.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    },
  },
});

export const { changeHardenFindingType, changeDefendFindingType, changeIsMisconfig, changeActiveFindingType, changeActiveFindingGraph, updateRefresh, changeMisconfigFilters } = findingsSlice.actions;
export default findingsSlice.reducer;
