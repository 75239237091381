import axios from "axios";
import { getHeaders } from "./triageAlertsAPI"
import urlMap from "../config/urls"
import { b64toBlob } from "../utils/utils"

export async function fetchJournalingInitialInfo() {
    const url = urlMap.baseURL + urlMap.journalingInitialInfoUrl
    let { data } = await axios.get(url, {
        headers: getHeaders()
    })
    return data;
  };
  
  export async function saveJournalingInfo( isJournaling, isQuarantine  ) {
    let params = { isJournaling, isQuarantine }
    const url = urlMap.baseURL + urlMap.journalingSaveInfoUrl
    let { data } = await axios.post(url, params, {
        headers: getHeaders()
    })
    return data;
  };
  
  export async function quarantineFormSubmit(tenant_id, client_id, client_secret, qEmail) {
    let params = { tenant_id, client_id, client_secret, qEmail };
    const url = urlMap.baseURL + urlMap.quarantineSetupUrl
    let { data } = await axios.post(url, params, {
        headers: getHeaders()
    })
    return data;
  };
  
  export async function fetchEmailStats() {
    const url = urlMap.baseURL + urlMap.journalingEmailStatsUrl
    let { data } = await axios.get(url, {
        headers: getHeaders()
    })
    return data;
  };
  
  export async function downloadJournalingLog() {
    //Confirm is that startTime needs to start of current day
    let startTime = new Date().setHours(0, 0, 0, 0);
    let endTime = new Date().getTime();
    const url = urlMap.baseURL + urlMap.journalingLogsUrl
    let { data } = await axios.get(url, {
        params: {
            startTime: startTime,
            endTime: endTime
        },
        headers: getHeaders()
    })
  
    const contentType = 'application/csv';
    const b64Data = data['message'];
    const blob = b64toBlob(b64Data, contentType);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'O365 Journaling Logs.csv';
    link.click();
  };
  
  