import { getRequestWithStartEndDate, getHeaders } from "./triageAlertsAPI"
import { setFromandToDateTime } from "../features/timeframe/timeframeConstants";
import urlMap from "../config/urls"
import axios from 'axios';

const plusOneDayString = (startDate) => {
    // console.log(startDate);
    let end = new Date(startDate);
    end.setDate(end.getDate() + 1);
    end.setMinutes(end.getMinutes() - 1); // Keeping it within the same day. 12:00 -> 11:59.
    return end.toISOString(); // endDate
}

const getIndexDate = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear().toString();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // this returns 0-11, not 1-12
    const day = dateObj.getDate().toString().padStart(2, '0'); // pad so '6' comes out as '06'
    let indexDate = year + month + day;
    return indexDate;
}

// returns a listing of resource types ( thus 'for resource type' url )
// date is a string in YYYYMMDD format, no from/interval field here.
export async function getInventoryResourceSummaryAPI(date, cloud = "aws", query = "") {
    const url = urlMap.baseURL + urlMap.inventoryForResourceTypeUrl
    
    const indexDate = getIndexDate(date);

    // let startDate = new Date(date).toISOString();
    // const endDate = plusOneDayString(date);

    let params = {
      indexDate: indexDate,
      query: query,
      cloud: cloud
    }

    const { data } = await axios.get(url, {
      params,
      headers: getHeaders()
    });
    return data;
}

// returns a listing of resources under this resourceType
// cloud & resourceType & group are mandatory fields
export async function getInventoryResourceTypeSummaryAPI(date, cloud = "aws", resourceType="", group = "", query = "") {
    const url = urlMap.baseURL + urlMap.inventoryByResourceTypeUrl
    // const { startDate, endDate } = setFromandToDateTime();
    // const startDate = new Date(date).toISOString();
    // const endDate = plusOneDayString(startDate);

    const indexDate = getIndexDate(date);

    let params = {
      indexDate: indexDate,
      query: query,
      resourceType: resourceType,
      group: group, // service
      cloud: cloud
    }

    const { data } = await axios.get(url, {
      params,
      headers: getHeaders()
    });
    return data;
}

export async function getInventoryRegionGraphDataAPI(date, cloud = "aws", query = "") {
    const url = urlMap.baseURL + urlMap.inventoryRegionHierarchyUrl
    // const { startDate, endDate } = setFromandToDateTime();
    // const startDate = new Date(date).toISOString();
    // const endDate = plusOneDayString(startDate);

    const indexDate = getIndexDate(date);

    let params = {
      indexDate: indexDate,
      query: query,
      cloud: cloud,
      graphKeys: true // if false, keys are descriptive instead of formatted for graphs
    }

    const { data } = await axios.get(url, {
      params,
      headers: getHeaders()
    });
    return data;
}

export async function getInventoryControlsFromQueryAPI(date, cloud = "aws", query="") {
    const url = urlMap.baseURL + urlMap.inventoryControlsUrl

    const indexDate = getIndexDate(date);

    let params = {
      indexDate: indexDate,
      query: query,
      cloud: cloud
    }

    const { data } = await axios.get(url, {
      params,
      headers: getHeaders()
    });
    return data.data;
}

export async function getInventorySevereAccountsAPI(date, cloud = "aws") {
    const url = urlMap.baseURL + urlMap.inventoryTopAccountsUrl
    // const { startDate, endDate } = setFromandToDateTime();
    const startDate = new Date(date).toISOString();
    const endDate = plusOneDayString(startDate);

    let params = {
      from: startDate,
      interval: endDate,
      cloud: cloud,
    }

    const { data } = await axios.get(url, {
      params,
      headers: getHeaders()
    });
    return data.data;
}

