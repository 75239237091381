import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import SideNavBar from "../features/navigationBar/SideNavBar";
import "./App.scss";
import { getThemeFromStorage } from "../utils/utils";
import { changeRole } from "./appSlice";
import { ROLE_MAP } from "../utils/constants";
import Routes from "../config/routes";

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();

  // adding in local storage usage.
  let storedTheme = getThemeFromStorage();

  const [theme, setTheme] = useState(storedTheme);
  
  // when theme updates, update local storage too.
  useEffect(() => {
    // set theme in local storage
    localStorage.setItem("theme", theme);
    // change html tag to create 'class=app' external background color
    document.querySelector("html").setAttribute("react-theme", theme);
  },[theme])

  // if(isAuthenticated) saveIdToken();

  useEffect(() => {
    const saveIdToken = async () => {
      let id_token = await getIdTokenClaims();
      let savedToken = localStorage.getItem("id_token");
      // console.log(`savedToken: ${savedToken}`)
      // console.log(id_token?.__raw)
      if (id_token?.__raw && id_token.__raw !== savedToken) {
        localStorage.setItem("id_token", id_token.__raw);
        console.log(`Updated id_token in localstorage`);
      }
    };
    if (isAuthenticated) {
      saveIdToken();
    }
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    // set user role based on information acquired from role type.
    let roleName = user?.['https://bluehexagon.ai/role'] ?? "admin";
    roleName = roleName.toUpperCase();
    dispatch(changeRole({userRole: ROLE_MAP[roleName], userRoleName: roleName}));
  }, [user])

  return (
    <div className={"App " + theme}>
      {isAuthenticated && <SideNavBar 
        themeUpdater={setTheme}
        currentTheme={theme}
      />}
      <div className={"content-margin--collapsed"}>
        <Routes />
      </div>
    </div>
  );
}

export default App;
