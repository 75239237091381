import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSystemAccountsAPI } from "../../../api/systemStatusAPI";

// polls audit for account ids
export const getSystemAccounts = createAsyncThunk(
  "systemStatusFetchAccounts",
  async (arbitraryString) => {
    const response = await getSystemAccountsAPI(arbitraryString);
    return response;
  }
);

const systemStatusAccountsSlice = createSlice({
  name: "systemStatusAccounts",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    refresh: 0,
  },
  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getSystemAccounts.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getSystemAccounts.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getSystemAccounts.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export const { updateRefresh } = systemStatusAccountsSlice.actions;
export default systemStatusAccountsSlice.reducer;
