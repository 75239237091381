import { getRequestWithStartEndDate, getHeaders } from "./triageAlertsAPI"
import { setFromandToDateTime } from "../features/timeframe/timeframeConstants";
import urlMap from "../config/urls"
import axios from 'axios';

export async function fetchReportsTableData(reportType, cloud) {
    const url = urlMap.baseURL + urlMap.reportsListingUrl
    let params = {
      reportType,
      cloud
    }
    const { data } = await axios.get(url, {
    	params,
    	headers: getHeaders()
    });
    return data;
}

// takes a URI and downloads a pdf blob.
export async function downloadReportAPI(uri) {
    const url = urlMap.baseURL + urlMap.reportsDownloadUrl;
    const { data } = await axios.get(url, {
      params: {
        uri: uri,
      },
        responseType: 'arraybuffer',
        headers: getHeaders(),
    });
    return data;
}

// takes a set of keys ( uris ) and attempts a delete on all of them.
// should ( ...in practice ) support deleting a -list- of reports
export async function deleteReportAPI(keys) {
    const url = urlMap.baseURL + urlMap.reportsDeleteUrl;
    let paramUri = `?keys=${encodeURIComponent(JSON.stringify(keys))}`;
    const finalUrl = url + paramUri;
    const { data } = await axios.get(finalUrl, {
        headers: getHeaders(),
    });
    return data;
}

// 'create report'
export async function createReportAPI(mode, email, reportData) {
  // Test code.
  // await new Promise(r => setTimeout(r, 2000));
  // return "failed";

  const url = urlMap.baseURL + urlMap.reportsCreateUrl;
  // const { startDate, endDate } = setFromandToDateTime();

  if ( !mode || !email || !reportData ) {
    return "failed";
  }

  let params = {};

  params.email = email; // user
  params.from = new Date(reportData.startDate).toISOString(); // startDate

  if ( mode == "exec" ) { // ExecReport
    params.report = "ExecReport";
    params.interval = new Date(reportData.endDate).toISOString(); // endDate

  }
  else if ( mode == "compliance" || mode == "inventory" ) { // Compliance or Findings
    if ( mode == "compliance" ) params.report = "Compliance"; // report
    if ( mode == "inventory") params.report = "Inventory";
    // end date +1 day from start date
    let end = new Date(reportData.startDate);
    end.setDate(end.getDate() + 1);
    end.setMinutes(end.getMinutes() - 1); // Keeping it within the same day. 12:00 -> 11:59.
    params.interval = end.toISOString(); // endDate

    if ( mode == "compliance" ) {
      params.standard = reportData.standard; // standard
    }

    params.domain = JSON.stringify(reportData.domain); // domain - list
    params.region = JSON.stringify(reportData.region); // region - list
    params.accountId = JSON.stringify(reportData.accountId); // accountId - list
  }
  // this contains both 'findings' and 'delta' types to take special care
  else if ( mode == "findings" ) {
    params.report = (reportData.type === "Delta") ? "FindingsDelta" : "Findings";

    // end date +1 day from start date
    let end = new Date(reportData.startDate);
    end.setDate(end.getDate() + 1);
    end.setMinutes(end.getMinutes() - 1); // Keeping it within the same day. 12:00 -> 11:59.
    params.interval = end.toISOString(); // endDate

    params.accountId = JSON.stringify(reportData.accountId); // accountId - list
    if ( reportData.type === "All" ) {
      // do not include domain or regions.
    }
    else { // for the 'custom' type, include domain & region
      params.domain = JSON.stringify(reportData.domain); // domain - list
      params.region = JSON.stringify(reportData.region); // region - list
    }

    if ( reportData.type === "Delta" && reportData.baseline !== null && reportData.baseline !== undefined ) {
      params.baseline = new Date(reportData.baseline).toISOString(); // baseline
    }

  }

  // Get the timezone string if possible...
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // polyfilled for IE
  if ( timezone === undefined || timezone === null ) timezone = ""; // Backup.

  // Get local timezone offset BASED ON START TIME. 
  // We assume that the entire time range is either daylight savings time or not.
  const timezoneOffset = new Date(reportData.startDate).getTimezoneOffset(); // -8 UTC = +480
  params.timezone = timezone;
  params.timezoneOffset = timezoneOffset;

  // console.log(reportData);
  // console.log(params);

  // const data = null;

  const { data } = await axios.get(url,{
    params,
    headers: getHeaders()
  });
  return data;

}
