import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { componentTypes } from "./triageAlertsConstants"
import { TABLE_TYPES } from "../../utils/constants"
import { getThreatReportSizeAPI, downloadThreatReportAPI, downloadRemediationStepsAPI } from "../../api/triageAlertsAPI";
import { fetchDiscoverSavedSearches } from "../../api/discoverAPI";

export const getTriageSavedSearches = createAsyncThunk(
  "savedSearches",
  async () => {
    const response = await fetchDiscoverSavedSearches();
    return response;
  }
);

export const getThreatReportSize = createAsyncThunk(
  "triageAlerts",
  async (urlParams) => {
    const{ hash } = urlParams;
    const response = await getThreatReportSizeAPI(hash);
    return response;
  }
);

export const downloadThreatReport = createAsyncThunk(
  "triageAlerts",
  async (urlParams) => {
    const{ hash } = urlParams;
    const response = await downloadThreatReportAPI(hash);
    return response;
  }
);

export const downloadRemediationSteps = createAsyncThunk(
  "remediation",
  async (urlParams) => {
    const { type } = urlParams;
    const response = await downloadRemediationStepsAPI(type);
    return response;
  }
);

const triageAlertsSlice = createSlice({
  name: "triageAlerts",
  initialState: {
    activeComponent: null,
    activeComponentType: null,
    tableName: null,
    tableType: null,
    savedSearches:[],
    isSavedSearchLoading: false,
    isSavedSearchStale: true,
    selectedSavedSearchId: null
  },
  reducers: {
    setActiveComponent(state, action) {
      const {
        activeComponent,
        activeComponentType,
        tableName,
        tableType
      } = action.payload;
      state.activeComponent = activeComponent;
      state.activeComponentType = activeComponentType;
      state.tableName = tableName;
      state.tableType = tableType;
    },
    changeTriageSelectedSavedSearchId(state, action) {
      const { selectedSavedSearchId } = action.payload;
      state.selectedSavedSearchId = selectedSavedSearchId;
    },
    setSavedSearchDataStale(state, action) {
      state.isSavedSearchStale = true;
    }
  },
  extraReducers: {
    [getTriageSavedSearches.pending]: (state, action) => {
      state.savedSearches = [];
      state.isSavedSearchLoading = true;
    },
    [getTriageSavedSearches.fulfilled]: (state, action) => {
      state.savedSearches = action.payload;
      state.isSavedSearchStale = false;
      state.isSavedSearchLoading = false;
    },
    [getTriageSavedSearches.rejected]: (state, action) => {
      state.savedSearches = [];
      state.isSavedSearchStale = false;
      state.isSavedSearchLoading = false;
    },
  },
});

export const { setActiveComponent, changeTriageSelectedSavedSearchId, setSavedSearchDataStale } = triageAlertsSlice.actions;
export default triageAlertsSlice.reducer;
