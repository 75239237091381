import { lazy } from "react";
import { ROLE_MAP } from "../utils/constants";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Investigation = lazy(() => import("../pages/investigation/Investigation"));
const TriageAlerts = lazy(() => import("../pages/triageAlerts/TriageAlerts"));
const Discover = lazy(() => import("../pages/discover/Discover"));
const Journaling = lazy(() => import("../pages/journaling/Journaling"));
const SystemStatus = lazy(() => import("../pages/systemStatus/SystemStatus"));
const Reports = lazy(() => import("../pages/reports/Reports"));
const Inventory = lazy(() => import("../pages/inventory/Inventory"));
const UserManagement = lazy(() => import("../pages/userManagement/UserManagement"));
const Logout = lazy(() => import("../pages/logout/Logout"));


export const paths = {
  accessDenied: "/404",
  dashboard: "/dashboard",
  investigation: "/incidents",
  triageAlerts: "/",
  inventory: "/inventory",
  discover: "/discover",
  journaling: "/journaling",
  systemStatus: "/system-status",
  reports: "/reports",
  userManagement: "/usermanagement",
  callback: "/callback",
  logout: "/logout",
};

// see also: routes.js & <AdminRoute>
// optional parameters:
// lock: <int> ( represents a level of RBAC, with 3 being the highest (e.g. admin) ) - could use a map for this.
// redirectTo: <path> -- redirect to this path instead of the default, used with lock.
export const routes = [
  // { path: paths.dashboard, component: Dashboard },
  { path: paths.discover, component: Discover },
  { path: paths.triageAlerts, component: TriageAlerts },
  { path: paths.investigation, component: Investigation, lock: ROLE_MAP.ANALYST },
  { path: paths.inventory, component: Inventory },
  { path: paths.journaling, component: Journaling, lock: ROLE_MAP.ADMIN },
  { path: paths.systemStatus, component: SystemStatus },
  { path: paths.reports, component: Reports },
  { path: paths.userManagement, component: UserManagement, lock: ROLE_MAP.ADMIN },
  { path: paths.callback, component: TriageAlerts },
  { path: paths.logout, component: Logout },
];
