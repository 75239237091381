import { combineReducers } from "@reduxjs/toolkit";
import tacticsReducer from "../pages/triageAlerts/interestingInsights/tacticsSlice";
import notificationsReducer from "../pages/triageAlerts/interestingInsights/notificationsSlice";
import insightsReducer from "../pages/triageAlerts/interestingInsights/insightsSlice";
import alertsReducer from "../pages/triageAlerts/triageAlertsTile/alertsSlice";
import findingsReducer from "../pages/triageAlerts/triageAlertsTile/findingsSlice";
import overlayReducer from "../pages/triageAlerts/triageAlertsTable/overlay/overlaySlice"
import misconfigurationHintsReducer from "../pages/triageAlerts/triageAlertsTable/overlay/overlayComponents/misconfigurationHintsSlice"
import resourceTagsReducer from "../pages/triageAlerts/triageAlertsTable/overlay/overlayComponents/resourceTagsSlice"
import triageAlertsTableReducer from "../pages/triageAlerts/triageAlertsTable/triageAlertsTableSlice";
import triageAlertsReducer from "../pages/triageAlerts/triageAlertsSlice";
import triageGraphsReducer from "../pages/triageAlerts/triageGraphs/triageGraphsSlice";
import timeframeReducer from "../features/timeframe/timeframeSlice";
import discoverReducer from "../pages/discover/discoverSlice";
import discoverTableReducer from "../pages/discover/discoverTable/discoverTableSlice"
import investigationReducer from "../pages/investigation/investigationSlice";
import workbenchDetailsReducer from "../pages/investigation/workbench/workbenchDetails/workbenchDetailsSlice";
import journalingReducer from "../pages/journaling/journalingSlice"
import systemStatusTableReducer from "../pages/systemStatus/systemStatusTable/systemStatusTableSlice";
import systemStatusAccountsReducer from "../pages/systemStatus/systemStatusAccounts/systemStatusAccountsSlice";
import userManagementTableReducer from "../pages/userManagement/userManagementTable/userManagementTableSlice";
import blacklistSliceReducer from "../features/blacklist/blacklistSlice";
import createReportButtonSliceReducer from "../features/createReportButton/createReportButtonSlice";
import reportsTableReducer from "../pages/reports/reportsTable/reportsTableSlice";
import inventoryTableReducer from "../pages/inventory/inventoryTable/inventoryTableSlice";
import cloudSwitcherReducer from "../features/cloudSwitcher/cloudSwitcherSlice";
import appReducer from "./appSlice";

const rootReducer = combineReducers({
  app: appReducer,
  tactics: tacticsReducer,
  insights: insightsReducer,
  notifications: notificationsReducer,
  alerts: alertsReducer,
  findings: findingsReducer,
  overlay: overlayReducer,
  misconfigurationHints: misconfigurationHintsReducer,
  resourceTags: resourceTagsReducer,
  triageAlertsTable: triageAlertsTableReducer,
  triageAlerts: triageAlertsReducer,
  triageGraphs: triageGraphsReducer,
  timeInterval: timeframeReducer,
  discover: discoverReducer,
  discoverTable:discoverTableReducer,
  investigation: investigationReducer,
  workbenchDetails: workbenchDetailsReducer,
  journaling: journalingReducer,
  systemStatusTable:systemStatusTableReducer,
  systemStatusAccounts:systemStatusAccountsReducer,
  blacklist:blacklistSliceReducer,
  reportsTable:reportsTableReducer,
  reportsCreate:createReportButtonSliceReducer,
  userManagementTable:userManagementTableReducer,
  inventoryTable: inventoryTableReducer,
  cloudSwitcher:cloudSwitcherReducer,
});

export default rootReducer;
