import axios from "axios";
import { getHeaders } from "./triageAlertsAPI"
import urlMap from "../config/urls"

const defaultValues = {
  email: "",
  user_id: "",
  family_name: "",
  given_name: "",
  role: "", // lower case.
}

export async function createUserAPI(detailsObj) {
    const url = urlMap.baseURL + urlMap.userCreateUrl
    let requestBody = {...defaultValues, ...detailsObj}

    const response = axios.post(url, requestBody, 
    {
      headers: getHeaders()
    })
    return response;

}

export async function editUserAPI(detailsObj) {
    const url = urlMap.baseURL + urlMap.userEditUrl
    let requestBody = {...defaultValues, ...detailsObj}

    const response = axios.post(url, requestBody, 
    {
      headers: getHeaders()
    })
    return response;
}

export async function deleteUserAPI(detailsObj) {
    const url = urlMap.baseURL + urlMap.userDeleteUrl
    let requestBody = {...defaultValues, ...detailsObj}

    const response = axios.post(url, requestBody, 
    {
      headers: getHeaders()
    })
    return response;
}

// must await axios.get
export async function getUserManagementTableDataAPI() {
    const url = urlMap.baseURL + urlMap.userListUrl
    const { data } = await axios.get(url,
    {
      headers: getHeaders()
    })
    return data;
}