import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchInvestigations, saveInvestigation, createInvestigationTicketAPI, saveInvestigationDetail, changeInvestigationStatus } 
  from "../../api/investigationAPI";

export const getInvestigationData = createAsyncThunk(
  "investigations",
  async () => {
    const response = await fetchInvestigations();
    return response;
  }
);

export const createInvestigation = createAsyncThunk(
  "createInvestigation",
  async (investigationName) => {
    const response = await saveInvestigation(investigationName);
    return response;
  }
);

// Create a jira ticket for this incident.
export const createInvestigationTicket = createAsyncThunk(
  "createInvestigationTicket",
  async ({investigationID}) => {
    const response = await createInvestigationTicketAPI(investigationID);
    return response;
  }
);

export const addInvestigationDetail = createAsyncThunk(
  "addDetails",
  async (detailsObj) => {
    const response = await saveInvestigationDetail(detailsObj);
    return response;
  }
);

export const changeInvestigationStatusCall = createAsyncThunk(
  "updateInvestigation",
  async ({investigationID, status}) => {
    const response = await changeInvestigationStatus(investigationID, status);
    return response;
  }
);

const workbenchSlice = createSlice({
  name: "workbench",
  initialState: {
    isLoading: false,
    error: null,
    jiraEnable: false,
    data: null,
    isInvestigationDataStale:true,
    activeTab: "workbench", // 'investigation' or 'workbench'
    activeStatus: "NEW", // 'NEW', 'IN_PROGRESS', 'COMPLETED' or 'ARCHIVED'
    lastActiveStatus: "NEW", // serves as memory if someone deselects activeStatus
    workbenchTab: "DATA",
  },
  reducers: {
    changeActiveTab(state, action) {
      state.activeTab = action.payload;
    },
    changeActiveStatus(state, action) {
      state.activeStatus = action.payload;
      if ( action.payload ) { // do not update this if the status is '' or null or undef.
        state.lastActiveStatus = action.payload;  
      }
    },
    changeWorkbenchTab(state, action) {
      state.workbenchTab = action.payload;
    },
    setInvestigationDataStale(state, action) {
      state.isInvestigationDataStale = true;
    }
  },
  extraReducers: {
    [getInvestigationData.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getInvestigationData.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
      state.jiraEnable = action.payload?.jiraEnable;
      state.isLoading = false;
      state.isInvestigationDataStale = false;
    },
    [getInvestigationData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
    [createInvestigation.fulfilled]: (state, action) => {
      state.isInvestigationDataStale = true;
    },
    [addInvestigationDetail.fulfilled]: (state, action) => {
      state.isInvestigationDataStale = true; // Can change jira feasability
    },
    [createInvestigationTicket.fulfilled]: (state, action) => {
      state.isInvestigationDataStale = true;
    },
    [changeInvestigationStatusCall.fulfilled]: (state, action) => {
      state.isInvestigationDataStale = true;
    },
  },
});

export const { changeActiveTab, changeActiveStatus, changeWorkbenchTab, setInvestigationDataStale } = workbenchSlice.actions;
export default workbenchSlice.reducer;
