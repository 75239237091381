import { createSlice } from "@reduxjs/toolkit";
import { timeIntervalOptions } from "./timeframeConstants";

const timeframeSlice = createSlice({
  name: "timeframe",
  initialState: {
    timeframe: localStorage.timeframe || timeIntervalOptions.last7Days.value,
    timeframeLabel:
      localStorage.timeframeLabel || timeIntervalOptions.last7Days.text,
    fromDate: (localStorage.fromDate && localStorage.fromDate !== "null")? localStorage.fromDate : null,
    toDate: (localStorage.toDate && localStorage.toDate !== "null") ? localStorage.toDate : null,
  },
  reducers: {
    setTimeFrame(state, action) {
      const { value, label } = action.payload;
      localStorage.setItem("timeframe", value);
      localStorage.setItem("timeframeLabel", label);
      state.timeframe = value;
      state.timeframeLabel = label;
    },
    setFromDate(state, action) {
      let fromDate = action.payload || "";
      localStorage.setItem("fromDate", fromDate);
      state.fromDate = fromDate;
    },
    setToDate(state, action) {
      let toDate = action.payload || "";
      localStorage.setItem("toDate", toDate);
      state.toDate = toDate;
    },
  },
});

export const { setTimeFrame, setFromDate, setToDate } = timeframeSlice.actions;
export default timeframeSlice.reducer;
