import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createReportAPI } from "../../api/reportsAPI";

export const createReport = createAsyncThunk(
  "reportsCreate",
  async (urlParams) => {
    const { mode, email, reportData } = urlParams;
    const response = await createReportAPI(mode, email, reportData);
    return response;
  }
);

const createReportButtonSlice = createSlice({
  name: "reportsCreate",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {},
  extraReducers: {
    [createReport.pending]: (state, action) => {
      state.data = null;
      state.isLoading = true;
      state.error = null;
    },
    [createReport.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [createReport.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export default createReportButtonSlice.reducer;
