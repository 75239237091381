import React from "react";
import { AiFillDashboard, AiFillAlert, AiOutlineFileSearch } from "react-icons/ai";
import { FaTools, FaSearch } from "react-icons/fa";
import { GrIntegration, GrSystem, GrStorage } from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdInventory2 } from "react-icons/md";
import { RiUserSettingsLine } from "react-icons/ri";
import { paths } from "../../config/routesConfig";
import { ROLE_MAP } from "../../utils/constants";

export const labels = {
  dashboard: "Dashboard",
  discover: "Discover",
  investigation: "Incidents",
  // integration: "Integration",
  triageAlerts: "Dashboard",
  journaling: "Configure",
  systemStatus: "System Status",
  reports: "Reports",
  userManagement: "User Management",
  inventory: "Inventory",
  // inspection: "Deep Storage Inspection",
};

// can add an optional 'lock' parameter for admin access only.
// no 'level of elevation' such that you can lock for both analysts and admins
export const navbarConfig = [
  // {
  //   label: labels.dashboard,
  //   path: paths.dashboard,
  //   icon: <AiFillDashboard size="1.5em" className="side-navbar__icon"/>,
  // },
  {
    label: labels.triageAlerts,
    path: paths.triageAlerts,
    icon: <AiFillAlert size="1.5em" className="side-navbar__icon"/>,
  },
  {
    label: labels.discover,
    path: paths.discover,
    icon: <FaSearch size="1.5em" className="side-navbar__icon"/>,
  },
  {
    label: labels.investigation,
    path: paths.investigation,
    icon: <AiOutlineFileSearch size="1.5em" className="side-navbar__icon"/>,
    lock: ROLE_MAP.ANALYST,
  },
  {
    label: labels.inventory,
    path: paths.inventory,
    icon: <MdInventory2 size="1.5em" className="side-navbar__icon"/>,
  },
  /*{
    label: labels.inspection,
    path: paths.dashboard, // dummy path
    icon: <GrStorage size="1.5em" className="side-navbar__icon"/>,
    lock: ROLE_MAP.ANALYST,
  },*/
  {
    label: labels.reports,
    path: paths.reports,
    icon: <HiOutlineDocumentReport size="1.5em" className="side-navbar__icon"/>,
  },
  {
    label: labels.systemStatus,
    path: paths.systemStatus,
    icon: <GrSystem size="1.5em" className="side-navbar__icon"/>,
  },
  {
    label: labels.journaling,
    path: paths.journaling,
    icon: <FaTools size="1.5em" className="side-navbar__icon"/>,
    lock: ROLE_MAP.ADMIN,
  },
  {
    label: labels.userManagement,
    path: paths.userManagement,
    icon: <RiUserSettingsLine size="1.5em" className="side-navbar__icon"/>,
    lock: ROLE_MAP.ADMIN,
  },
  // {
  //   label: labels.integration,
  //   path: paths.integration,
  //   icon: <GrIntegration size="1.5em" className="side-navbar__icon"/>,
  // },
];
