import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchJournalingInitialInfo , saveJournalingInfo, quarantineFormSubmit,
  downloadJournalingLog, fetchEmailStats } from "../../api/journalingAPI";

export const getInitialInfo = createAsyncThunk(
  "journalingInfo",
  async () => {
    const response = await fetchJournalingInitialInfo();
    return response;
  }
);

export const updateJournalingInfo = createAsyncThunk(
  "updateJournalingInfo",
  async ( params ) => {
    const { O365Support, quarantineEnable } = params 
    const response = await saveJournalingInfo(O365Support, quarantineEnable);
    return response;
  }
);

export const submitQuarantineForm = createAsyncThunk(
  "submitQuarantine",
  async ( params ) => {
    const { tenantId, clientId, clientSecret, quarantineEmaildId } = params
    const response = await quarantineFormSubmit(tenantId, clientId,
      clientSecret, quarantineEmaildId);
    return response;
  }
);

export const getEmailStats = createAsyncThunk(
  "emailStats",
  async ( params ) => {
    const response = await fetchEmailStats();
    return response;
  }
);

export const downloadJournalLog = createAsyncThunk(
  "downloadLog",
  async ( params ) => {
    const response = await downloadJournalingLog();
    return response;
  }
);

const journalingSlice = createSlice({
  name: "journaling",
  initialState: {
    O365Support: false,
    quarantineEnable: false,
    emaildId:null,
    lastEmailRecievedTime:null,
    quarantineFormValues: {
        tenantId: '',
        clientId: '',
        clientSecret: '',
        quarantineEmaildId: ''
    },
    emailStats:{},
    isJournalingStale: true,
    isLoading: false,
  },
  reducers: {
    toggleState(state, action) {
      const { O365Support, quarantineEnable } = action.payload;
      state.O365Support = O365Support;
      state.quarantineEnable = quarantineEnable;
    },
    setJournalingDataStale(state, action) {
      state.isJournalingStale = true;
    }
  },
  extraReducers: {
    [getInitialInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getInitialInfo.fulfilled]: (state, action) => {
      state.O365Support = action.payload.journaling?.is_monitoring
      state.quarantineEnable = action.payload.journaling?.is_quarantine
      state.emaildId = action.payload.journaling?.email
      state.lastEmailRecievedTime = action.payload.journaling?.last_email_received
      state.quarantineFormValues.clientId = action.payload.secrets?.client_id
      state.quarantineFormValues.clientSecret = action.payload.secrets?.client_secret
      state.quarantineFormValues.tenantId = action.payload.secrets?.tenant_id
      state.quarantineFormValues.quarantineEmaildId = action.payload.secrets?.qEmail
      state.isLoading = false;
      state.isJournalingStale = false;
    },
    [updateJournalingInfo.fulfilled]: (state, action) => {
      state.O365Support = action.payload.is_monitoring
      state.quarantineEnable = action.payload.is_quarantine
    },
    [getEmailStats.fulfilled]: (state, action) => {
      state.emailStats = action.payload
    }
  },
});

export const { toggleState, setJournalingDataStale } = journalingSlice.actions;
export default journalingSlice.reducer;