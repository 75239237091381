import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAttackByTactics } from "../../../api/triageAlertsAPI";

export const geAttackByTactics = createAsyncThunk(
  "attackByTactics",
  async () => {
    const response = await fetchAttackByTactics();
    return response;
  }
);

const tacticsSlice = createSlice({
  name: "tactics",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },

  reducers: {},
  extraReducers: {
    [geAttackByTactics.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [geAttackByTactics.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [geAttackByTactics.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    },
  },
});

export default tacticsSlice.reducer;
