const urlMap = {
    baseURL: window.env.API_BASE_URL,
    discoverDataUrl: "discoversearch/discoverdata",
    connLogsUrl: "discoversearch/connlogs",
    dnsLogsUrl: "discoversearch/dnslogs",
    sslLogsUrl: "discoversearch/ssllogs",
    rpdLogsUrl: 'discoversearch/rdplogs',
    rpcLogsUrl: 'discoversearch/rpclogs',
    smpLogsUrl: 'discoversearch/smblogs',
    smbFileLogsUrl: 'discoversearch/smbfilelogs',
    sshLogsUrl: 'discoversearch/sshlogs',
    ntlmLogsUrl: 'discoversearch/ntlmlogs',
    dhcpLogsUrl: 'discoversearch/dhcplogs',
    kerberosLogsUrl: 'discoversearch/kerberoslogs',
    httpLogsUrl: 'discoversearch/httplogs',
    cloudtrailLogsUrl: 'discoversearch/cloudtraillogs',
    azureActivityLogsUrl: 'discoversearch/azureactivitylogs',
    threatOverviewUrl: "threat/overview",
    threatBarChartUrl: "threat/barcharts",
    threatRuntimeTopAlertsChartUrl: "threat/runtimetopalertschart",
    insightsOverviewUrl: "discoversearch/insightsoverview",
    attackByTacticsOverviewUrl: "threat/attackbytactics",
    notificationOverviewUrl: "threat/notificationoverview",
    notificationsUrl: "threat/notifications",
    findingOverviewUrl: "threat/findings",
    threatDataUrl: "threat/threatsdata",
    infectedHostsUrl: "threat/infectedhosts",
    discoverUniqueFieldsUrl: "discoversearch/uniquevalues",
    discoverSavedSearchesUrl: "discoversearch/searches",
    discoverSavedSuggestionsUrl:"discoversearch/suggestions",
    discoverRelevantInsightsUrl: "discoversearch/relevantinsights",
    discoverSaveSuggestionUrl:"discoversearch/savesuggestion",
    discoverSaveSearchUrl:"discoversearch/savesearch",
    discoverUpdateSearchUrl:"discoversearch/updatesearch",
    discoverDeleteSearchUrl:"discoversearch/deletesearch",
    investigationsUrl:"investigations",
    investigationsCreateJiraUrl:"investigations/createjira",
    investigationDetailsUrl:"investigationdetails",
    investigationDetailsDeleteUrl: "investigationdetails/delete",
    changeInvestigationStatusUrl: "investigations/changestatus",
    inventoryForResourceTypeUrl: "inventory/resourcetype",
    inventoryByResourceTypeUrl: "inventory/resource",
    inventoryRegionHierarchyUrl: "inventory/regionhierarchy",
    inventoryControlsUrl: "inventory/controls",
    inventoryTopAccountsUrl: "inventory/topaccounts",
    cloudInventoryResourceInfoUrl: "/cloudinventory/resourceinfo",
    journalingInitialInfoUrl: "journaling/initialInfo",
    journalingSaveInfoUrl: "journaling/save",
    quarantineSetupUrl: "secrets/store",
    journalingEmailStatsUrl: "journaling/info",
    journalingLogsUrl: "journaling/logs",
    systemStatusUrl:"system/systemview",
    systemStatusAccountsUrl:"system/accounts",
    systemStatusDatadogUrl:"system/dog",
    blacklistHashUrl: "blocking/blacklisthash",
    whitelistFindingUrl: "blocking/whitelistfinding",
    threatGetReportSizeUrl: "threat/threatreportsize",
    threatDownloadReportUrl: "threat/threatreportdownload",
    remediationDownloadUrl: "threat/remediationdownload",
    reportsListingUrl: "reports/reportslist",
    reportsDownloadUrl: "reports/reportdownload",
    reportsCreateUrl: "reports/generatereport",
    reportsDeleteUrl: "reports/deletereport",
    userCreateUrl: "user/create",
    userEditUrl: "user/edit",
    userDeleteUrl: "user/delete",
    userListUrl: "user/get",

  };

  export default urlMap;
