// Get the theme based on local storage & return a string.
// If no theme is found, set it to the fallback.
// Example use case: A subcomponent modal that pops up outside the div/app block & isn't a child of the theme.
export const getThemeFromStorage = (fallback = "dark-theme") => {
	let storedTheme = localStorage.getItem("theme"); // get theme
	if ( !storedTheme ) { // set to fallback if it doesn't exist
    localStorage.setItem("theme", fallback);
    storedTheme = fallback;
  }
  return storedTheme;
}

// Returns true for "", "  ", null, undefined
export const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
}

const getDateFromESDateString = (date) => {
  let dateString = date.replace(/\s/, 'T').trim();
  if(!dateString.endsWith('Z')) dateString = dateString + 'Z';
  return new Date(dateString);
}

const getDateFromEpochTime = (ts) => {
  // Can potentially get ts in nanoseconds.
  // Always returns ts in seconds.
  ts = parseInt(ts.substr(0, 10));

  // Returns user's local time when ts given in milliseconds.
  return new Date(ts * 1000);
}

const toLocaleDateStringSupportsLocales = () => {
  try {
    new Date().toLocaleDateString('i');
  } catch (e) {
    return e.name === 'RangeError';
  }
  return false;
}

export const getFormattedDateFromEpochDate = (date) => {
  if ( null == date ) return null
  if ( date === "" ) return null; 
  if ( date === "-" ) return "-";
  const convertedDate = getDateFromEpochTime(date)
  return getFormattedDateTime(convertedDate);
}

export const getFormattedDateFromEsDate = (date) => {
  if(null == date) return null
  if ( date === "" ) return null; 
  let convertedDate = getDateFromESDateString(date);
  return getFormattedDateTime(convertedDate)
}

// Common method to get Formatted DateTime if required overload the method params. DON'T write new method.
export const getFormattedDateTime = (date, showms = false, multipicationRequired = false) => {
  if (null !== date && undefined !== date) {
      let convertedDate;
      if (multipicationRequired === true) {
        convertedDate = new Date(date * 1000);
      } else {
          convertedDate = new Date(date);
      };

      // To convert the UTC date into browser time zone
      // const d = new Date(convertedDate.getTime() - convertedDate.getTimezoneOffset() * 60 * 1000);
      const d = convertedDate

      let day = d.getDate(),
          month = d.getMonth() + 1,
          year = d.getFullYear(),
          hour = d.getHours(),
          minute = d.getMinutes(),
          second = d.getSeconds(),
          millisecond = d.getMilliseconds();

      day = day < 10 ? `0${day}` : day;
      month = month < 10 ? `0${month}` : month;
      hour = hour < 10 ? `0${hour}` : hour;
      minute = minute < 10 ? `0${minute}` : minute;
      second = second < 10 ? `0${second}` : second;
      millisecond = millisecond < 10 ? `00${millisecond}` : (millisecond < 100 ? `0${millisecond}` : millisecond);

      if (showms) {
          return `${year}-${month}-${day} ${hour}:${minute}:${second}.${millisecond}`;
      } else {
          return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
  }
  return null;
}

//return date in the format "Jan 05, 2018, 02:15 PM" for browser supporting locale, otherwise returns isoString
export const getDateTimeFormat2 = (date, type="es")=>{
  let isoDate;
  if ( type == "es") isoDate = getDateFromESDateString(date);
  else if ( type == "epoch" ) isoDate = getDateFromEpochTime(date);
  if(toLocaleDateStringSupportsLocales){
      let options = {
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          month: "short",
          year: "numeric"
      }
      // will return "Invalid Date" if date is not a valid ISO String date
      return new Date(isoDate).toLocaleDateString('en-US',options)
  }
  return isoDate;
}

export const isStringIpAddress = (word)=>{
  if(word && typeof(word) === "string"){
      if(isNaN(word[0])) return false;
      let ipAddregex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
      return ipAddregex.test(word);
  }
  return false;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const getUpdatedTableFilter = (key, value, oldFrozenSetFilters) => {
  let newSetFilter = Object.assign({}, oldFrozenSetFilters);
    if (newSetFilter[key]) {
      let innerObj = [...newSetFilter[key]]
      let index = newSetFilter[key].indexOf(value);
      if (index > -1) {
        innerObj.splice(index, 1);
        if(innerObj.length < 1) delete newSetFilter[key];
        else newSetFilter[key] = innerObj;
      } else {
        innerObj.push(value);
        newSetFilter[key] = innerObj;
      }
    } else {
      newSetFilter[key] = [];
      newSetFilter[key].push(value);
    }
    return newSetFilter
}

// copy whatever is passed into clipboard
export const copyData = ( cell ) => {
  // Create text area
  let newText = document.createElement("textarea");
  newText.id = "copy-cell-id";
  let newContent = document.createTextNode(cell);
  newText.appendChild(newContent);
  let appDiv = document.getElementsByClassName("App")[0];
  appDiv.appendChild(newText);

  // Copy from it
  newText.select();
  document.execCommand('copy');

  // Delete textarea now that we're done
  newText.parentNode.removeChild(newText);

}
