import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createUserAPI, editUserAPI, deleteUserAPI, getUserManagementTableDataAPI } from "../../../api/userManagementAPI";

export const createUser = createAsyncThunk(
  "userManagementCreate",
  async (detailsObj) => {
    const response = await createUserAPI(detailsObj);
    return response;
  }
);

export const editUser = createAsyncThunk(
  "userManagementEdit",
  async (detailsObj) => {
    const response = await editUserAPI(detailsObj);
    return response;
  }
);

export const deleteUser = createAsyncThunk(
  "userManagementDelete",
  async (detailsObj) => {
    const response = await deleteUserAPI(detailsObj);
    return response;
  }
);

export const getUserManagementTableData = createAsyncThunk(
  "userManagementTable",
  async () => {
    const response = await getUserManagementTableDataAPI();
    return response;
  }
);

const userManagementTableSlice = createSlice({
  name: "userManagementTable",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    page: 1,
    size: 10,
    isUserManagementStale: true,
  },
  reducers: {},
  extraReducers: {
    [getUserManagementTableData.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getUserManagementTableData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isUserManagementStale = false;
      state.isLoading = false;
    },
    [getUserManagementTableData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
      state.isUserManagementStale = false;
    },
    [createUser.fulfilled]: (state, action) => {
      state.isUserManagementStale = true;
    },
    [editUser.fulfilled]: (state, action) => {
      state.isUserManagementStale = true;
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.isUserManagementStale = true;
    },
  },
});

export default userManagementTableSlice.reducer;
