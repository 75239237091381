import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import {  } from "../../api/cloudSwitcherAPI"; // not needed yet!

// network calls here.
// export const createUser = createAsyncThunk(
//   "userManagementCreate",
//   async (detailsObj) => {
//     const response = await createUserAPI(detailsObj);
//     return response;
//   }
// );

const cloudSwitcherSlice = createSlice({
  name: "cloudSwitcher",
  initialState: {
    isLoading: false,
    activeClouds: ["aws"], // only 1 at a time, single-cloud for now.
    activeRegions: {},
    activeResources: {},
    refresh: 0,
    // isCloudConfigStale: false,
  },
  reducers: {
    changeActiveClouds(state, action) {
      state.activeClouds = action.payload;
    },
    changeActiveRegions(state, action) {
      state.activeRegions = action.payload;
    },
    changeActiveResources(state, action) {
      state.activeResources = action.payload;
    },
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
  },
});

export const { changeActiveClouds, changeActiveRegions, changeActiveResources, updateRefresh }  = cloudSwitcherSlice.actions;
export default cloudSwitcherSlice.reducer;
