import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchNotificationOverview } from "../../../api/triageAlertsAPI";

export const getNotificationOverview = createAsyncThunk(
  "notificationOverview",
  async (urlParams) => {
    const { cloud } = urlParams;
    const response = await fetchNotificationOverview(cloud);
    return response;
  }
);

export const getNotificationOverviewWithIP = createAsyncThunk(
  "notificationOverviewWithIP",
  async (urlParams) => {
    const { ipAddress, cloud } = urlParams;
    const response = await fetchNotificationOverview(cloud, ipAddress);
    return response;
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    ipData: null,
    ipDataIsLoading: false,
    refresh: 0,
  },

  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getNotificationOverview.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getNotificationOverview.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getNotificationOverview.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    },
    [getNotificationOverviewWithIP.pending]: (state, action) => {
      state.ipDataIsLoading = true;
      state.error = null;
    },
    [getNotificationOverviewWithIP.fulfilled]: (state, action) => {
      state.ipData = action.payload;
      state.ipDataIsLoading = false;
    },
    [getNotificationOverviewWithIP.rejected]: (state, action) => {
      state.error = action.error.message;
      state.ipDataIsLoading = false;
    },
  },
});

export const { updateRefresh } = notificationSlice.actions;
export default notificationSlice.reducer;
