import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInventoryResourceSummaryAPI, getInventoryResourceTypeSummaryAPI, 
  getInventoryRegionGraphDataAPI, getInventorySevereAccountsAPI, getInventoryControlsFromQueryAPI } from "../../../api/inventoryAPI";

export const getInventoryResourceSummary = createAsyncThunk(
  "inventoryTable",
  async (urlParams) => {
    const { cloud, query, date } = urlParams;
    let response;
    if ( !query ) {
      response = await getInventoryResourceSummaryAPI(date, cloud);
    }
    else {
      response = await getInventoryResourceSummaryAPI(date, cloud, query);
    }
    return response;
  }
);

export const getInventoryResourceTypeSummary = createAsyncThunk(
  "inventoryTable",
  async (urlParams) => {
    const { date, cloud, resourceType, group, query } = urlParams;
    let response;
    if ( !query ) {
      response = await getInventoryResourceTypeSummaryAPI(date, cloud, resourceType, group);
    }
    else {
      response = await getInventoryResourceTypeSummaryAPI(date, cloud, resourceType, group, query);
    }
    return response;
  }
);



// hierarchical representation
export const getInventoryRegionGraphData = createAsyncThunk(
  "inventoryGraph",
  async (urlParams) => {
    const { date, cloud, query } = urlParams; // could add query here if needed.
    let response;
    if ( !query ) {
      response = await getInventoryRegionGraphDataAPI(date, cloud);
    }
    else {
      response = await getInventoryRegionGraphDataAPI(date, cloud, query);
    }

    return response;
  }
);

export const getInventorySevereAccounts = createAsyncThunk(
  "inventoryAccounts",
  async (urlParams) => {
    const { date, cloud } = urlParams;
    let response = await getInventorySevereAccountsAPI(date, cloud);
    return response;
  }
);

export const getInventoryControlsFromQuery = createAsyncThunk(
  "inventoryControls",
  async (urlParams) => {
    const { date, cloud, query } = urlParams;
    let response = await getInventoryControlsFromQueryAPI(date, cloud, query);
    return response;
  }
);


const inventoryTableSlice = createSlice({
  name: "inventoryTable",
  initialState: {
    isLoading: false,
    error: null,
    data: null, // getInventoryResourceSummary and getInventoryResourceTypeSummary load into this.
    filters: {},
    isGraphLoading: false,
    graphData: null,
    graphError: null,
    refresh: 0,
    searchKeys: {},
    isAccountsLoading: false,
    accountsError: null,
    topAccounts: null,
    isControlsLoading: false,
    controlsData: null,
    controlsError: null,
  },
  reducers: {
    updateRefresh(state) {
      state.refresh++;
    },
    updateSearchKeys(state, action) { // table keys...
      state.searchKeys = action.payload;
    },
    updateFilters(state, action) {
      // const { filters } = action.payload;
      state.filters = action.payload;
    },
  },
  extraReducers: {
    [getInventoryResourceSummary.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getInventoryResourceSummary.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getInventoryResourceSummary.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
    [getInventoryResourceTypeSummary.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getInventoryResourceTypeSummary.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getInventoryResourceTypeSummary.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
    [getInventoryRegionGraphData.pending]: (state, action) => {
      state.isGraphLoading = true;
      state.graphError = null;      
    },
    [getInventoryRegionGraphData.fulfilled]: (state, action) => {
      state.graphData = action.payload;
      state.isGraphLoading = false;
    },
    [getInventoryRegionGraphData.rejected]: (state, action) => {
      state.graphError = action.error.message;
      state.graphData = null;
      state.isGraphLoading = false;
    },
    [getInventorySevereAccounts.pending]: (state, action) => {
      state.topAccounts = null;
      state.isAccountsLoading = true;
      state.accountsError = null;      
    },
    [getInventorySevereAccounts.fulfilled]: (state, action) => {
      state.topAccounts = action.payload;
      state.isAccountsLoading = false;
    },
    [getInventorySevereAccounts.rejected]: (state, action) => {
      state.accountsError = action.error.message;
      state.topAccounts = null;
      state.isAccountsLoading = false;
    },
    [getInventoryControlsFromQuery.pending]: (state, action) => {
      state.controlsData = null;
      state.isControlsLoading = true;
      state.controlsError = null;      
    },
    [getInventoryControlsFromQuery.fulfilled]: (state, action) => {
      state.controlsData = action.payload;
      state.isControlsLoading = false;
    },
    [getInventoryControlsFromQuery.rejected]: (state, action) => {
      state.controlsError = action.error.message;
      state.controlsData = null;
      state.isControlsLoading = false;
    },
  },
});

export const { updateRefresh, updateSearchKeys, updateFilters } = inventoryTableSlice.actions;
export default inventoryTableSlice.reducer;
