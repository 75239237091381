import axios from "axios";
import { getHeaders } from "./triageAlertsAPI"
import urlMap from "../config/urls"

export async function fetchInvestigations() {
    const url = urlMap.baseURL + urlMap.investigationsUrl;
    const { data } = await axios.get(url, {
        headers: getHeaders(),
      });
    return data;
}

export async function saveInvestigation(investigationName){
    const url = urlMap.baseURL + urlMap.investigationsUrl
    let requestBody = { title:investigationName }
    let response_result = axios.post(url, requestBody,
      {
          headers: getHeaders()
      });
    return response_result;
}

export async function createInvestigationTicketAPI(investigationId) {
    const url = urlMap.baseURL + urlMap.investigationsCreateJiraUrl;

    // const requestBody = investigationId;
    let requestBody = { id:investigationId }
    const { data } = await axios.post(url, requestBody, {
      headers: getHeaders()
    });
    return data;
}

export async function fetchInvestigationDetails(investigationId) {
    const url = urlMap.baseURL + urlMap.investigationDetailsUrl;
    const { data } = await axios.get(url, {
        params:{investigationId},
        headers: getHeaders()
        });
    return data;
}

export async function saveInvestigationDetail(detailsObj){
    let detailsTemp = {
        investigationIds: [],
        type: "",
        insight: "",
        finding: "",
        savedSearchId: "", // older entries might have this as null
        rowId: "",
        searchQuery: "",
        filters: {},
        count: 10,
        cloud: "-",
        fromDate: "2011-10-05T14:48:00.000Z",
        toDate : "2011-10-05T14:48:00.000Z"
    }
    let requestBody = {...detailsTemp,...detailsObj}
    console.log(requestBody);
    const url = urlMap.baseURL + urlMap.investigationDetailsUrl
    let response_result = axios.post(url, requestBody,
      {
          headers: getHeaders()
      });
    return response_result;
}

export async function deleteInvestigationDetail(detailsObj){
    let detailsTemp = {
        id: "",
        investigationId: "",
        type: "",
        insight: "",
        finding: "",
        savedSearchId: "", // older entries might have this as null
        rowId: "",
        searchQuery: "",
        filters: {},
        fromDate: "2011-10-05T14:48:00.000Z",
        toDate : "2011-10-05T14:48:00.000Z",
        count: 10,
        createdBy: "",
        createdAt: 0,
    }
    let requestBody = {...detailsTemp,...detailsObj}
    const url = urlMap.baseURL + urlMap.investigationDetailsDeleteUrl;
    let response_result = axios.post(url, requestBody,
      {
          headers: getHeaders()
      });
    return response_result;

    // console.log(requestBody)
    // return null;
}
 
export async function changeInvestigationStatus(investigationId, status="NEW") {
    const url = urlMap.baseURL + urlMap.changeInvestigationStatusUrl;

    const requestBody = { investigationId, status };
    const { data } = await axios.post(url, requestBody, {
      headers: getHeaders()
    });
    return data;
}
