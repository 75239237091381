import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { routes, paths } from "./routesConfig";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ROLE_MAP } from "../utils/constants";

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const Routes = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const { userRole } = app;

  const allRoutes = routes.map((route, index) => {
    if ( route.lock && userRole < route.lock ) {
      const redirectPath = route.redirectTo ?? paths.triageAlerts;
      return (
        <Redirect from={route.path} to={redirectPath} />
      );
    }
    else {
      return (
        <ProtectedRoute
          key={route.path}
          exact
          path={route.path}
          component={route.component}
        />
      );
    }
  });
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {allRoutes}
        {/* <Redirect from="/callback" to={paths.triageAlerts} /> */}
      </Switch>
    </Suspense>
  );
};

export default Routes;
