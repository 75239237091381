import axios from "axios";
import { componentTypes, findingsMap  } from "../pages/triageAlerts/triageAlertsConstants";
import { setFromandToDateTime } from "../features/timeframe/timeframeConstants";
import  urlMap from "../config/urls"

var CancelToken = axios.CancelToken;

export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    crossdomain: "true",
    Authorization: localStorage.getItem("id_token"),
  };
};

export const getParams = () => {
  const { startDate, endDate } = setFromandToDateTime();
  return {
    from: startDate,
    interval: endDate,
    fromPage: 0,
    size: 10,
    sortOn: "ts",
    sortType: "desc",
    filters: {},
    query: "",
  };
};

export const getRequestWithStartEndDate = async (url, cancellable = null) => {
  cancellable && cancellable()
  const { startDate, endDate } = setFromandToDateTime();
  let params =  {
    from: startDate,
    interval: endDate,
  };
  const { data } = await axios.get(url,{
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { cancellable = c; })
  });
  return data;
}

let fetchThreatOverviewCancel;
export async function fetchThreatOverview(cloud="unknown") {
  fetchThreatOverviewCancel && fetchThreatOverviewCancel();
  const url = urlMap.baseURL + urlMap.threatOverviewUrl
  const { startDate, endDate } = setFromandToDateTime();
  let params = {
    from: startDate,
    interval: endDate,
    cloud: cloud,
  }
  const { data } = await axios.get(url, {
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchThreatOverviewCancel = c; })
  });
  return data;
}

let getTriageResourceTypeGraphAPICancel; 
export async function getTriageResourceTypeGraphAPI (cloud="unknown", filters={}) {
  getTriageResourceTypeGraphAPICancel && getTriageResourceTypeGraphAPICancel();
  const url = urlMap.baseURL + urlMap.threatBarChartUrl
  const { startDate, endDate } = setFromandToDateTime();
  filters = filters || {}; // anti null undef.
  let params = {
    from: startDate,
    interval: endDate,
    type: "Resources",
    cloud: cloud,
    filters: filters
  }
  const { data } = await axios.get(url, {
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { getTriageResourceTypeGraphAPICancel = c; })
  });
  return data.data;
}

let getTriageAccountsGraphAPICancel;
export async function getTriageAccountsGraphAPI (cloud="unknown", filters={}) {
  getTriageAccountsGraphAPICancel && getTriageAccountsGraphAPICancel();
  const url = urlMap.baseURL + urlMap.threatBarChartUrl
  const { startDate, endDate } = setFromandToDateTime();
  filters = filters || {}; // anti null undef.
  let params = {
    from: startDate,
    interval: endDate,
    type: "Accounts",
    cloud: cloud,
    filters: filters
  }
  const { data } = await axios.get(url, {
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { getTriageAccountsGraphAPICancel = c; })
  });
  return data.data;
}

let getTriageRuntimeTopAlertsGraphAPICancel;
export async function getTriageRuntimeTopAlertsGraphAPI (cloud="unknown") {
  getTriageRuntimeTopAlertsGraphAPICancel && getTriageRuntimeTopAlertsGraphAPICancel();
  const url = urlMap.baseURL + urlMap.threatRuntimeTopAlertsChartUrl
  const { startDate, endDate } = setFromandToDateTime();
  let params = {
    from: startDate,
    interval: endDate,
    cloud: cloud,
  }
  const { data } = await axios.get(url, {
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { getTriageRuntimeTopAlertsGraphAPICancel = c; })
  });
  return data.data;
}

let fetchInsightsCancel;
export async function fetchInsights(cloud="unknown") {
  fetchInsightsCancel && fetchInsightsCancel();
  const url = urlMap.baseURL + urlMap.insightsOverviewUrl
  const { startDate, endDate } = setFromandToDateTime();
  let params = {
    from: startDate,
    interval: endDate,
    cloud: cloud,
  }
  const { data } = await axios.get(url, {
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchInsightsCancel = c; })
  });
  return data.data;
}

let fetchAttackByTacticsCancel;
export async function fetchAttackByTactics() {
  const url = urlMap.baseURL + urlMap.attackByTacticsOverviewUrl
  let data = await getRequestWithStartEndDate(url, fetchAttackByTacticsCancel);
  return data.data;
}

let fetchTriageAlertsTableDataCancel;
export async function fetchTriageAlertsTableData(tableType, activeComponent, query, filters, page, size, sortOn, sortType, activeTab="file") {
  fetchTriageAlertsTableDataCancel && fetchTriageAlertsTableDataCancel();
  let blfilters = {}
  if(tableType === componentTypes.alerts) {
    blfilters.state = activeComponent
    blfilters.source = activeTab
    if ( activeComponent.localeCompare("approved", undefined, {sensitivity: 'base'}) == 0 ) { blfilters.state = "whitelisted" }
    if ( activeComponent.localeCompare("banned", undefined, {sensitivity: 'base'}) == 0 ) { blfilters.state = "blacklisted" }
  }
  else if (tableType === componentTypes.tactics) 
    blfilters.tactic = activeComponent
  const url = urlMap.baseURL + urlMap.threatDataUrl
  const { startDate, endDate } = setFromandToDateTime();
  const fromPage=(page-1) * size
  filters = filters || {};query = query || '';
  let paramUri = `?from=${startDate}&interval=${endDate}&fromPage=${fromPage}&size=${size}&sortOn=${sortOn}`+
  `&sortType=${sortType}&query=${encodeURIComponent(query)}&filters=${encodeURIComponent(JSON.stringify(filters))}`+
  `&blfilters=${encodeURIComponent(JSON.stringify(blfilters))}`;
  let finalUrl = url + paramUri;
  const { data } = await axios.get(finalUrl, {
    // params:{ ...getParams(), ...{ blfilters }, query, filters, fromPage, size, sortOn, sortType},
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchTriageAlertsTableDataCancel = c; })
  });
  return data;
}

// if query is empty, get all notifs for a time period
// if query is non-empty ( should be an IP ), get all notifs -based on an IP-
let fetchNotificationOverviewCancel;
export async function fetchNotificationOverview(cloud = "", query = "") {
  fetchNotificationOverviewCancel && fetchNotificationOverviewCancel();
  const url = urlMap.baseURL + urlMap.notificationOverviewUrl
  const { startDate, endDate } = setFromandToDateTime();
  let params =  {
    from: startDate,
    interval: endDate,
    query: query,
    cloud: cloud,
  };
  const { data } = await axios.get(url,{
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchNotificationOverviewCancel = c; })
  });
  let result = data.data
  for (const key in result) {
    result[key].title=result[key].title.replace("ATTACK::","SMB::")
  }
  return result;
}

let fetchFindingsOverviewCancel;
export async function fetchFindingsOverview(cloud, query = "", findingType="Unauthorized Activity", filters={}) {
  fetchFindingsOverviewCancel && fetchFindingsOverviewCancel();
  const findingUrlKey = findingsMap[findingType] || "";
  const url = urlMap.baseURL + urlMap.findingOverviewUrl
  const { startDate, endDate } = setFromandToDateTime();
  filters = filters || {}; // anti null undef.
  let params =  {
    from: startDate,
    interval: endDate,
    query: query,
    type: findingUrlKey,
    cloud: cloud,
    filters: filters
  };
  const { data } = await axios.get(url,{
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchFindingsOverviewCancel = c; })
  });
  let result = data.data // dive in.
  for ( const findingOverallType in result ) {
    for ( const dataKey in result[findingOverallType] ) {
      for (const key in result[findingOverallType][dataKey] ) {
        result[findingOverallType][dataKey][key].title=result[findingOverallType][dataKey][key].title.replace("ATTACK::","SMB::")
      }
    }
  }
  
  return result;
}

const minusOneDayString = (endDate) => {
  // console.log(endDate);
  let start = new Date(endDate);
  start.setDate(start.getDate() - 1);
  return start.toISOString(); // endDate
}

export async function getResTagsAPI(eventData, type, cloud) {
  const url = urlMap.baseURL + urlMap.cloudInventoryResourceInfoUrl
  const { startDate, endDate } = setFromandToDateTime();

  let paramUri = `?from=${startDate}&interval=${endDate}&type=${type}`+
  `&cloud=${cloud}&data=${encodeURIComponent(JSON.stringify(eventData))}`;
  let finalUrl = url + paramUri;

  const { data } = await axios.get(finalUrl, {
    headers: getHeaders(),
  });
  return data;

}

// simplified version with an always 1-day date range. Overloading fetchNotificationsData with so many things isn't good.
// This does not cancel because it repeats potentially many times for misconfig hints.
export async function fetchNotificationsDataForMisconfigHints(query, filters, page, size, sortOn, sortType, cloud) {
  const url = urlMap.baseURL + urlMap.notificationsUrl
  const endDate = new Date().toISOString(); // now
  const startDate = minusOneDayString(endDate); // now - 1d

  const fromPage=(page-1) * size
  filters = filters || {};
  query = query || '';

  let paramUri = `?from=${startDate}&interval=${endDate}&fromPage=${fromPage}&size=${size}&sortOn=${sortOn}`+
  `&sortType=${sortType}&query=${encodeURIComponent(query)}&cloud=${cloud}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  let finalUrl = url + paramUri;
  const { data } = await axios.get(finalUrl, {
    // params:{ ...getParams(), query, filters, fromPage, size, sortOn, sortType},
    headers: getHeaders(),
  });
  return data;
}


let fetchNotificationsDataCancel;
export async function fetchNotificationsData(activeComponent, query, filters, page, size, sortOn, sortType, cloud, activeType=null, activeGraph=null) {
  fetchNotificationsDataCancel && fetchNotificationsDataCancel();
  const url = urlMap.baseURL + urlMap.notificationsUrl
  const { startDate, endDate } = setFromandToDateTime();
  const fromPage=(page-1) * size
  filters = filters || {};
  query = query || '';
  if ( activeComponent?.length ) {
    filters = {
      ...filters,
      event_note:[`"${activeComponent}"`]
    }  
  }
  if ( activeType ) {
    const findingUrlKey = findingsMap[activeType] || "";
    filters = {
      ...filters,
      findingType:findingUrlKey // represents finding types from findingsSlice
    }
  }
  if ( activeGraph ) {
    filters = {
      ...filters,
      graphType:activeGraph
    }
  }

  let paramUri = `?from=${startDate}&interval=${endDate}&fromPage=${fromPage}&size=${size}&sortOn=${sortOn}`+
  `&sortType=${sortType}&query=${encodeURIComponent(query)}&cloud=${cloud}&filters=${encodeURIComponent(JSON.stringify(filters))}`;
  let finalUrl = url + paramUri;
  const { data } = await axios.get(finalUrl, {
    // params:{ ...getParams(), query, filters, fromPage, size, sortOn, sortType},
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchNotificationsDataCancel = c; })
  });
  return data;
}

let fetchInfectedHostsByHashCancel;
export async function fetchInfectedHostsByHash(hash){
  fetchInfectedHostsByHashCancel && fetchInfectedHostsByHashCancel();
  const url = urlMap.baseURL + urlMap.infectedHostsUrl
  const { startDate, endDate } = setFromandToDateTime();
  let params =  {
    from: startDate,
    interval: endDate,
    hash
  };
  const { data } = await axios.get(url,{
    params,
    headers: getHeaders(),
    cancelToken: new CancelToken(function executor(c) { fetchInfectedHostsByHashCancel = c; })
  });
  return data;
}

// -1 = does not exist.
export async function getThreatReportSizeAPI(hash){
  // if ( hash.length != 64 ) {
  //   return false; // bad hash, just return false.
  // }

  const url = urlMap.baseURL + urlMap.threatGetReportSizeUrl;
  const { data } = await axios.get(url, {
    params: {
      hash: hash,
    },
      headers: getHeaders()
  });

  return data;
}

export async function downloadThreatReportAPI(hash){
  const url = urlMap.baseURL + urlMap.threatDownloadReportUrl;
  const { data } = await axios.get(url, {
    params: {
      hash: hash,
    },
      headers: getHeaders()
  });

  return data;
}

// takes a 'type' ( possibly irrelevant, depending on how PDF is stored ) and downloads remediation steps based on that.
export async function downloadRemediationStepsAPI(type) {
    const url = urlMap.baseURL + urlMap.remediationDownloadUrl;
    const { data } = await axios.get(url, {
      params: {
        type: type,
      },
        responseType: 'arraybuffer',
        headers: getHeaders(),
    });
    return data;
}
