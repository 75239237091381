import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDiscoverTableData, fetchDiscoverAvailableFields } from "../../../api/discoverAPI";
import { fetchNotificationsData } from "../../../api/triageAlertsAPI";
import { TABLE_TYPES } from "../../../utils/constants";

export const getDiscoverTableData = createAsyncThunk(
  "discoverTable",
  async (urlParams) => {
    const { tableType, searchQuery, filters, page, size, sortColumn, sort, insight, cloud } = urlParams;
    let response;
    if ( tableType === TABLE_TYPES.NOTIFICATIONS ) {
      // "" = 'activeComponent' -> useful for filters / based on security type
      response = await fetchNotificationsData(insight, searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    }
    else {
      response = await fetchDiscoverTableData( tableType, searchQuery,
        filters, page, size, sortColumn, sort, insight, cloud );  
    }
    return response;
  }
);

export const getDiscoverAvailableFields = createAsyncThunk(
  "discoverUniqueFields",
  async () => {
    const response = await fetchDiscoverAvailableFields();
    return response;
  }
);

const discoverTableSlice = createSlice({
  name: "discoverTable",
  initialState: {
    isLoading: false,
    error: null,
    tableType: TABLE_TYPES.NETWORK_INFERENCE,
    selectedColumns: [],
    data:[],
    uniqueFields:[],
    totalRecords: 0,
    searchQuery: "",
    insight:"",
    insightTitle:"",
    filters:{},
    sortColumn: "ts",
    sort: "desc",
    page: 1,
    size: 10,
    selectedQueryTitle: null,
    discoverTableLock: false,
    refresh: 0,
  },
  reducers: {
    changeTableType(state, action) {
      const { tableType } = action.payload;
      state.tableType = tableType;
      state.page = 1;
      state.sortColumn = "ts";
      state.sort = "desc";
      state.filters={};
      state.insight="";
      state.insightTitle="";
    },
    changeTableTypeSticky(state, action) { // do not reset filters or search query, but do reset insights.
      const { tableType } = action.payload;
      state.tableType = tableType;
      state.page = 1;
      state.sortColumn = "ts";
      state.sort = "desc";
      // state.filters={};
      state.insight="";
      state.insightTitle="";
    },
    changeSearchQuery(state, action) {
      const { searchQuery, selectedQueryTitle } = action.payload;
      state.searchQuery = searchQuery;
      state.selectedQueryTitle = selectedQueryTitle;
      state.page = 1;
    },
    changeInsight(state, action) {
      const { insight, insightTitle } = action.payload;
      state.insight = insight;
      state.insightTitle = insightTitle;
      state.page = 1;
    },
    changeFilter(state, action) {
      const { filters } = action.payload;
      state.filters = filters;
      state.page = 1;
    },
    changeSort(state, action) {
      const { sortColumn, sort } = action.payload;
      state.sortColumn = sortColumn;
      state.sort = sort;
    },
    changePagination(state, action) {
      const { page, size } = action.payload;
      state.page = page;
      state.size = size;
    },
    changeDiscoverTableLock(state, action) {
      const { lock } = action.payload;
      state.discoverTableLock = lock;
    },
    updateRefresh(state) {
      state.refresh++;
    },
  },
  extraReducers: {
    [getDiscoverTableData.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getDiscoverTableData.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
      state.totalRecords = action.payload?.totalRecords;
      state.selectedColumns = action.payload?.selectedColumns ?? null;
      state.isLoading = false;
    },
    [getDiscoverTableData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.totalRecords = 0;
      state.selectedColumns = null;
      state.isLoading = false;
    },
    [getDiscoverAvailableFields.pending]: (state, action) => {
      state.uniqueFields = [];
    },
    [getDiscoverAvailableFields.fulfilled]: (state, action) => {
      state.uniqueFields = action.payload;
    }
  },
});

export const { changeTableType, changeTableTypeSticky, changeSearchQuery, changeFilter, changeSort, changePagination,
  changeInsight, changeDiscoverTableLock, updateRefresh } = discoverTableSlice.actions;
export default discoverTableSlice.reducer;
