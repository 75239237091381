import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getResTagsAPI } from "../../../../../api/triageAlertsAPI";

export const getResTags = createAsyncThunk(
  "resTags",
  async (urlParams) => {
    const { eventData, type, cloud } = urlParams;
    const response = await getResTagsAPI(eventData, type, cloud);
    return response;
  }
);

const resourceTagsSlice = createSlice({
  name: "resourceTags",
  initialState: {
      data: null,
      isLoading: false,
      error: null,
  },
  reducers: {
  },
  extraReducers: {
    [getResTags.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getResTags.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getResTags.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    }
  }
});

export default resourceTagsSlice.reducer;
