import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchTriageAlertsTableData, fetchNotificationsData } from "../../../api/triageAlertsAPI";
import { componentTypes } from "../triageAlertsConstants"
import { fetchDiscoverTableData } from "../../../api/discoverAPI";

export const getTriageAlertsTableData = createAsyncThunk(
  "triageAlertsTable",
  async (urlParams) => {
    const { activeComponent, activeComponentType, tableType, searchQuery, tableSpecificFilters : filters, page, size, sortColumn, 
      sort, activeTab, cloud, trueActiveType : activeType, activeGraph } = urlParams;
    let response
    if (
      activeComponentType === componentTypes.alerts ||
      activeComponentType === componentTypes.tactics
    ) {
      response = await fetchTriageAlertsTableData(activeComponentType, activeComponent,
        searchQuery, filters, page, size, sortColumn, sort, activeTab);
    } else if (activeComponentType === componentTypes.insights){
      response = await fetchDiscoverTableData(tableType, searchQuery, filters, 
        page, size, sortColumn, sort, activeComponent, cloud );
    } else if (activeComponentType === componentTypes.notification){
      response = await fetchNotificationsData(activeComponent, searchQuery, filters, 
        page, size, sortColumn, sort, cloud, activeType, activeGraph );
    }
    return response;
  }
);

const triageAlertsTableSlice = createSlice({
  name: "triageAlertsTable",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    selectedInsightsColumns: null,
    totalRecords: null,
    searchQuery:"",
    filters:{},
    sortColumn: "ts",
    sort: "desc",
    page: 1,
    size: 10,
    refresh: 0,
  },
  reducers: {
    resetTable(state, action) {
      state.searchQuery = "";
      state.filters = {};
      state.sortColumn = "ts";
      state.sort = "desc";
      state.page = 1;
      state.size = 10;
    },
    changeSearchQuery(state, action) {
      const { searchQuery } = action.payload;
      state.searchQuery = searchQuery;
      state.page = 1;
    },
    changeFilter(state, action) {
      const { filters } = action.payload;
      state.filters = filters;
      state.page = 1;
    },
    changeSort(state, action) {
      const { sortColumn, sort } = action.payload;
      state.sortColumn = sortColumn;
      state.sort = sort;
    },
    changePagination(state, action) {
      const { page, size } = action.payload;
      state.page = page;
      state.size = size;
    },
    updateRefresh(state) {
      state.refresh++;
    },
  },
  extraReducers: {
    [getTriageAlertsTableData.pending]: (state, action) => {
      state.isLoading = true;
      state.selectedInsightsColumns = null;
      state.error = null;
      state.totalRecords = null;
    },
    [getTriageAlertsTableData.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
      state.totalRecords = action.payload?.totalRecords;
      state.selectedInsightsColumns = action.payload?.selectedColumns ?? null;
      state.isLoading = false;
    },
    [getTriageAlertsTableData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.selectedInsightsColumns = null;
      state.isLoading = false;
    },
  },
});

export const { resetTable, changeSearchQuery, changeFilter, changeSort, changePagination, updateRefresh } = triageAlertsTableSlice.actions;
export default triageAlertsTableSlice.reducer;
