import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDiscoverSavedSearches, fetchRelevantInsights, saveSearch, updateSearch,
  deleteSearchApi, saveSuggestionAPI, getSuggestionsAPI } from "../../api/discoverAPI";

export const getSavedSearches = createAsyncThunk(
  "savedSearches",
  async () => {
    const response = await fetchDiscoverSavedSearches();
    return response;
  }
);

export const getRelevantInsights = createAsyncThunk( // change callee
  "relevantInsights", 
  async (urlParams) => {
    const { ipAddress, cloud } = urlParams;
    const response = await fetchRelevantInsights(ipAddress, cloud);
    return response;
  }
);

export const getSuggestions = createAsyncThunk(
  "suggestions",
  async () => {
    const response = await getSuggestionsAPI();
    return response;
  }
);

export const saveSuggestion = createAsyncThunk(
  "saveSuggestion",
  async (saveSuggestionObject) => {
    const response = await saveSuggestionAPI(saveSuggestionObject);
    return response;
  }
);

export const upsertSearch = createAsyncThunk(
  "upsertSearch", 
  async (params) => {
    let {saveSearchObject, searchId} = params
    let response
    if(searchId)
      response = await updateSearch(saveSearchObject, searchId);
    else
      response = await saveSearch(saveSearchObject);
    return response;
  }
);

export const deleteSearch = createAsyncThunk(
  "deleteSearch", 
  async (searchId) => {
    let response = await deleteSearchApi(searchId);
    return response;
  }
);

const discoverSlice = createSlice({
  name: "discover",
  initialState: {
    pageTitle:"Discover",
    savedSearches:[],
    savedSuggestions:[],
    relevantInsights:{},
    loadingRelevantInsights:false,
    isSavedSearchStale: true,
    isSavedSuggestionsStale: true,
    selectedSavedSearchId: null
  },
  reducers: {
    changeSelectedSavedSearchId(state, action) {
      const { selectedSavedSearchId } = action.payload;
      state.selectedSavedSearchId = selectedSavedSearchId;
    },
    setSavedSearchDataStale(state, action) {
      state.isSavedSearchStale = true;
    }
  },
  extraReducers: {
    [getSavedSearches.pending]: (state, action) => {
      state.savedSearches = [];
    },
    [getSavedSearches.fulfilled]: (state, action) => {
      state.savedSearches = action.payload;
      state.isSavedSearchStale = false;
    },
    [getRelevantInsights.pending]: (state, action) => {
      state.relevantInsights = {};
      state.loadingRelevantInsights = true;
    },
    [getRelevantInsights.fulfilled]: (state, action) => {
      state.relevantInsights = action.payload?.data;
      state.loadingRelevantInsights = false;
    },
    [getRelevantInsights.rejected]: (state, action) => {
      state.loadingRelevantInsights = false;
    },
    [upsertSearch.fulfilled]: (state, action) => {
      state.isSavedSearchStale = true;
    },
    [deleteSearch.fulfilled]: (state, action) => {
      state.isSavedSearchStale = true;
    },
    [saveSuggestion.fulfilled]: (state, action) => {
      state.isSavedSuggestionsStale = true;
    },
    [getSuggestions.pending]: (state, action) => {
      state.savedSuggestions = [];
    },
    [getSuggestions.fulfilled]: (state, action) => {
      state.savedSuggestions = action.payload;
      state.isSavedSuggestionsStale = false;
    },
  },
});

export const { changeSelectedSavedSearchId, setSavedSearchDataStale } = discoverSlice.actions;
export default discoverSlice.reducer;