import axios from "axios";
import { getHeaders, getRequestWithStartEndDate } from "./triageAlertsAPI"
import urlMap from "../config/urls"

export async function fetchSystemStatusTableData() {
    const url = urlMap.baseURL + urlMap.systemStatusUrl
    const data  = await getRequestWithStartEndDate(url)
    return data;
}

export async function getDatadogDataAPI() {
    const url = urlMap.baseURL + urlMap.systemStatusDatadogUrl
    // const data  = await getRequestWithStartEndDate(url)
    return "<div><div>test</div><div>test</div><div style='color:blue'>test</div></div>";
    // return data;
}

export async function getSystemAccountsAPI() {
    const url = urlMap.baseURL + urlMap.systemStatusAccountsUrl
    
    // now-7d to now.
    // let now = new Date();
    // let sevenAgo = new Date();
    // sevenAgo.setDate(sevenAgo.getDate() - 7);

    // const params = {
    //   fromDate: now,
    //   toDate: sevenAgo
    // }

    const { data } = await axios.get(url, {
      // params,
      headers: getHeaders()
    })

    return data;
}