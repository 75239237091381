export const timeIntervalOptions = {
  last5Mins: { text: "Last 5 mins", value: "8" },
  today: { text: "Today", value: "4" },
  last15Mins: { text: "Last 15 mins", value: "5" },
  thisWeek: { text: "This Week", value: "13" },
  last1Hour: { text: "Last 1 hour", value: "6" },
  lastDay: { text: "Last day", value: "2" },
  last4Hours: { text: "Last 4 hours", value: "7" },
  last7Days: { text: "Last 7 days", value: "3" },
  last12Hours: { text: "Last 12 hours", value: "1" },
  thisMonth: { text: "Last 30 Days", value: "12" },
  last24Hours: { text: "Last 24 hours", value: "11" },
  dateRange: { text: "Date range", value: "10" },
};

export function setFromandToDateTime() {
  let interval = localStorage.timeframe,
  fromDate =   localStorage.fromDate,
  toDate =  localStorage.toDate;
  let startDate = "";
  let endDate = "";

  if (interval === timeIntervalOptions.dateRange.value && fromDate && toDate) {
    startDate = new Date(fromDate).toISOString();
    endDate = new Date(toDate).toISOString();
  } else {
    startDate = new Date();
    endDate = new Date().toISOString();
    switch (interval || timeIntervalOptions.last7Days.value) {
      case timeIntervalOptions.last12Hours.value:
        startDate.setHours(startDate.getHours() - 12);
        break;
      case timeIntervalOptions.lastDay.value:
        startDate.setDate(startDate.getDate() - 1);
        break;
      case timeIntervalOptions.last7Days.value:
        startDate.setDate(startDate.getDate() - 7);
        break;
      case timeIntervalOptions.today.value:
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        break;
      case timeIntervalOptions.last15Mins.value:
        startDate.setMinutes(startDate.getMinutes() - 15);
        break;
      case timeIntervalOptions.last1Hour.value:
        startDate.setHours(startDate.getHours() - 1);
        break;
      case timeIntervalOptions.last4Hours.value:
        startDate.setHours(startDate.getHours() - 4);
        break;
      case timeIntervalOptions.last5Mins.value:
        startDate.setMinutes(startDate.getMinutes() - 5);
        break;
      case timeIntervalOptions.last24Hours.value:
        startDate.setHours(startDate.getHours() - 24);
        break;
      case timeIntervalOptions.thisMonth.value:
        startDate.setMonth(startDate.getMonth() - 1);
        break;

      case timeIntervalOptions.thisWeek.value:
        let day = startDate.getDay();
        let diff = startDate.getDate() - day + (day === 0 ? -6 : 1);
        startDate.setDate(diff);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
        break;

      default:
        break;
    }
    startDate = startDate.toISOString();
  }

  return {
    startDate,
    endDate,
  };
}

function setFormattedDateTime(date) {
  if (null != date && undefined !== date) {
    const d = new Date(date);

    let day = d.getUTCDate(),
      month = d.getUTCMonth() + 1,
      year = d.getUTCFullYear(),
      hours = d.getUTCHours(),
      minutes = d.getUTCMinutes(),
      seconds = d.getSeconds(),
      milliseconds = d.getUTCMilliseconds();

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
  return null;
}
