import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchInsights } from "../../../api/triageAlertsAPI";

export const getInsights = createAsyncThunk("insights", 
  async (urlParams) => {
    const { cloud } = urlParams;
    const response = await fetchInsights(cloud);
    return response;
  }
);

const insightsSlice = createSlice({
  name: "insights",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    refresh: 0,
  },

  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getInsights.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [getInsights.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getInsights.rejected]: (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    },
  },
});

export const { updateRefresh } = insightsSlice.actions;
export default insightsSlice.reducer;
