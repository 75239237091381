import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchNotificationsDataForMisconfigHints } from "../../../../../api/triageAlertsAPI";

export const getS3BucketTableData = createAsyncThunk(
  "s3BucketHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

export const getBucketTableData = createAsyncThunk(
  "bucketHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

export const getArnTableData = createAsyncThunk(
  "arnHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

export const getEcrArnTableData = createAsyncThunk(
  "ecrArnHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

export const getInstanceIdTableData = createAsyncThunk(
  "instanceIdHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

export const getVpcIdTableData = createAsyncThunk(
  "vpcIdHintsData",
  async (urlParams) => {
    const { searchQuery, filters, page, size, sortColumn, sort, cloud } = urlParams;
    const response = await fetchNotificationsDataForMisconfigHints(searchQuery, filters, 
        page, size, sortColumn, sort, cloud );
    return response;
  }
);

// get data for table calls...

// We only ever get 1 page, and it's entirely based on cloud + a simple search term.
// And we will load some of these graphs depending on what keys we find ( very likely not all six )
const misconfigurationHintsSlice = createSlice({
  name: "misconfigurationHints",
  initialState: {
      s3BucketIsLoading: false,
      s3BucketError: null,
      s3BucketData: null,
      bucketIsLoading: false,
      bucketError: null,
      bucketData: null,
      arnIsLoading: false,
      arnError: null,
      arnData: null,
      ecrArnIsLoading: false,
      ecrArnError: null,
      ecrArnData: null,
      instanceIdIsLoading: false,
      instanceIdError: null,
      instanceIdData: null,
      vpcIdIsLoading: false,
      vpcIdError: null,
      vpcIdData: null,
  },
  reducers: {
  },
  extraReducers: {
    [getS3BucketTableData.pending]: (state, action) => {
      state.s3BucketIsLoading = true;
      state.s3BucketError = null;
    },
    [getS3BucketTableData.fulfilled]: (state, action) => {
      state.s3BucketData = action.payload?.data;
      state.s3BucketIsLoading = false;
    },
    [getS3BucketTableData.rejected]: (state, action) => {
      state.s3BucketError = action.error.message;
      state.s3BucketIsLoading = false;
    },
    [getBucketTableData.pending]: (state, action) => {
      state.bucketIsLoading = true;
      state.bucketError = null;
    },
    [getBucketTableData.fulfilled]: (state, action) => {
      state.bucketData = action.payload?.data;
      state.bucketIsLoading = false;
    },
    [getBucketTableData.rejected]: (state, action) => {
      state.bucketError = action.error.message;
      state.bucketIsLoading = false;
    },
    [getArnTableData.pending]: (state, action) => {
      state.arnIsLoading = true;
      state.arnError = null;
    },
    [getArnTableData.fulfilled]: (state, action) => {
      state.arnData = action.payload?.data;
      state.arnIsLoading = false;
    },
    [getArnTableData.rejected]: (state, action) => {
      state.arnError = action.error.message;
      state.arnIsLoading = false;
    },
    [getEcrArnTableData.pending]: (state, action) => {
      state.ecrArnIsLoading = true;
      state.ecrArnError = null;
    },
    [getEcrArnTableData.fulfilled]: (state, action) => {
      state.ecrArnData = action.payload?.data;
      state.ecrArnIsLoading = false;
    },
    [getEcrArnTableData.rejected]: (state, action) => {
      state.ecrArnError = action.error.message;
      state.ecrArnIsLoading = false;
    },
    [getInstanceIdTableData.pending]: (state, action) => {
      state.instanceIdIsLoading = true;
      state.instanceIdError = null;
    },
    [getInstanceIdTableData.fulfilled]: (state, action) => {
      state.instanceIdData = action.payload?.data;
      state.instanceIdIsLoading = false;
    },
    [getInstanceIdTableData.rejected]: (state, action) => {
      state.instanceIdError = action.error.message;
      state.instanceIdIsLoading = false;
    },
    [getVpcIdTableData.pending]: (state, action) => {
      state.vpcIdIsLoading = true;
      state.vpcIdError = null;
    },
    [getVpcIdTableData.fulfilled]: (state, action) => {
      state.vpcIdData = action.payload?.data;
      state.vpcIdIsLoading = false;
    },
    [getVpcIdTableData.rejected]: (state, action) => {
      state.vpcIdError = action.error.message;
      state.vpcIdIsLoading = false;
    },
  }
});

// export const { } = misconfigurationHintsSlice.actions;
export default misconfigurationHintsSlice.reducer;
