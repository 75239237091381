import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SideNav, {
  Toggle,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { FiHexagon, FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import Routes from "../../config/routes";
import { navbarConfig } from "./sideNavBarConstant";
import { ROLE_MAP } from "../../utils/constants";
import { WiDaySunny, WiMoonAltWaxingGibbous3 } from "react-icons/wi";
import { RiArrowGoBackLine } from "react-icons/ri";
import { BiHelpCircle } from "react-icons/bi";
//import { ReactComponent as DarkModeLogo } from "../../assets/logo-solid-white.svg";
import { ReactComponent as DarkModeLogo } from "../../assets/qualys-shield.svg";
//import { ReactComponent as LightModeLogo } from "../../assets/logo-color.svg";
import { ReactComponent as LightModeLogo } from "../../assets/qualys-shield.svg";
//import { ReactComponent as DarkModeLogoFull } from "../../assets/alt-all-white.svg";
import { ReactComponent as DarkModeLogoFull } from "../../assets/qualys.svg";
import { ReactComponent as LightModeLogoFull } from "../../assets/qualys.svg";

import "./SideNavBar.scss";

// Theme Switching Fragment - For use with 'SideNav', returns a 'NavItem'
const ThemeSwitcher = (props) => {
  const title = "Theme Switch"
  if ( props.currentTheme === "dark-theme" ) {
    return (
      <NavItem className="side-navbar__theme-switcher" eventKey="light-theme" onClick={(e)=>{props.themeUpdater("light-theme"); e.stopPropagation();} }>
        <NavIcon><WiMoonAltWaxingGibbous3 size="1.5em" className="side-navbar__icon side-navbar__moon"/></NavIcon>
        <NavText style={{ paddingRight: 32 }} title={title}>
          <span className="side-navbar__text">{title}</span>
        </NavText>
      </NavItem>
    )
  }
  else if ( props.currentTheme === "light-theme" ) {
    return (
      <NavItem className="side-navbar__theme-switcher" eventKey="dark-theme" onClick={(e)=>{props.themeUpdater("dark-theme"); e.stopPropagation();} }>
        <NavIcon><WiDaySunny size="1.5em" className="side-navbar__icon"/></NavIcon>
        <NavText style={{ paddingRight: 32 }} title={title}>
          <span className="side-navbar__text">{title}</span>
        </NavText>
      </NavItem>
    )
  }
  else {
    return null;
  }
}

const SiteSwitcher = () => {
  return (
    <NavItem eventKey="switch-site" onClick={(e)=>{window.location.href="https://classic.gobluehexagon.ai"; e.stopPropagation();} }>
      <NavIcon><RiArrowGoBackLine size="1.5em" className="side-navbar__icon"/></NavIcon>
      <NavText style={{ paddingRight: 32 }} title="Classic UI">
        <span className="side-navbar__text">Classic UI</span>
      </NavText>
    </NavItem>
  );
}

const HelpPortal = () => {
  return (
    <NavItem eventKey="help-portal" onClick={(e)=>{window.open("https://bluehexagonai.atlassian.net/wiki/spaces/BHDOC/overview", "_blank"); e.stopPropagation();} }>
      <NavIcon><BiHelpCircle size="1.5em" className="side-navbar__icon"/></NavIcon>
      <NavText style={{ paddingRight: 32 }} title="Help Portal">
        <span className="side-navbar__text">Help Portal</span>
      </NavText>
    </NavItem>
  );
}

const LogoutNavItem = () => {
  const title = "Logout";
  const { logout } = useAuth0();
  return (
    <NavItem className="side-navbar__footer" onClick={(e) => {logout({ returnTo: window.location.origin }); e.stopPropagation();} }>
      <NavIcon><FiLogOut size="1.5em" className="side-navbar__icon"/></NavIcon>
      <NavText style={{ paddingRight: 32 }} title={title}>
        <span className="side-navbar__text">{title}</span>
      </NavText>
    </NavItem>
  )
}

const SideNavBar = (props) => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const { userRole } = app;

  const [expanded, setExpanded] = useState(false);

  //const onToggle = (expanded) => {
  //  setExpanded(expanded);
  //};

  const themes = ["dark-theme", "light-theme"];
  const specialOpLinks = themes.concat("switch-site", "help-portal");
  // console.log(specialOpLinks);
  const { currentTheme, themeUpdater } = props; // how to do ...desconstruction in functional component?
  const expandedClass = expanded ? "side-navbar--expanded" : "side-navbar--collapsed";

  return (

    <Route
      render={(props) => (
        <>
          <SideNav
            onSelect={(selected) => {
              if ( specialOpLinks.includes(selected) ) {
                return; //short circuit if this is a theme or special non-route link.
              }
              const to = selected;
              if (props.location.pathname !== to) {
                props.history.push(to);
              }
            }}
            //onToggle={onToggle}
            expanded={expanded}
            onClick={()=>{setExpanded(!expanded)}} // not enough, need to trigger toggle.
            className={"side-navbar " + expandedClass}
            style={{
              position: "fixed",
              // overflowY: "auto",
            }}
          >
            <div className="sidenavbar_logo">
              {/*<FiHexagon size="1.5em" className="sidenavbar_logo_icon" />*/}
              {expanded ? 
                (currentTheme == "dark-theme" ? <DarkModeLogoFull className="sidenavbar_logo_full"/> : <LightModeLogoFull className="sidenavbar_logo_full" />)
                :
                (currentTheme == "dark-theme" ? <DarkModeLogo className="sidenavbar_logo_icon" /> : <LightModeLogo className="sidenavbar_logo_icon" />)}
            </div>

            {/*<Toggle
              componentClass={(obj) => {
                return (
                  <button className="sidenavbar_toggle" onClick={obj.onClick}>
                    {expanded ? (
                      <IoIosArrowDropleftCircle size="1.5em" />
                    ) : (
                      <IoIosArrowDroprightCircle size="1.5em" />
                    )}
                  </button>
                );
              }}
            />*/}

            {/*<SideNav.Toggle />*/}
            <SideNav.Nav selected={props.location.pathname}>
              {navbarConfig.map((navItem) => {
                if ( navItem.lock && userRole < navItem.lock ) { return null; }
                return (
                  <NavItem eventKey={navItem.path} onClick={(e) => {e.stopPropagation()}}>
                    <NavIcon>{navItem.icon}</NavIcon>
                    <NavText style={{ paddingRight: 32 }} title={navItem.label}>
                      <span className="side-navbar__text">{navItem.label}</span>
                    </NavText>
                  </NavItem>
                );
              })}
              <ThemeSwitcher themeUpdater={themeUpdater} currentTheme={currentTheme}/>
              {/*<SiteSwitcher />*/}
              <HelpPortal />
              <LogoutNavItem />
            </SideNav.Nav>
          </SideNav>

          {/*<div
            className={expanded ? "side-navbar__margin--expanded" : "side-navbar__margin--collapsed" }
          >
            <Routes />
          </div>*/}
        </>
      )}
    />
  );
};

export default React.memo(SideNavBar);
