import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchInfectedHostsByHash } from "../../../../api/triageAlertsAPI";

export const getInfectedHosts = createAsyncThunk(
  "infectedhosts",
  async (hash) => {
    const response = await fetchInfectedHostsByHash(hash)
    return response;
  }
);

const overlaySlice = createSlice({
  name: "overlay",
  initialState: {
      selectedHash:null,
      infectedHosts:[]
  },
  reducers: {
     setSelectedHash(state, action) {
      const { selectedHash } = action.payload;
      state.selectedHash = selectedHash;
    }
  },
  extraReducers: {
    [getInfectedHosts.pending]: (state, action) => {
      state.infectedHosts = null;
    },
    [getInfectedHosts.fulfilled]: (state, action) => {
      state.infectedHosts = action.payload;
    }
  }
});

export const { setSelectedHash } = overlaySlice.actions;
export default overlaySlice.reducer;
