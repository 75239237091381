import { configureStore } from "@reduxjs/toolkit";
import { paths } from "../config/routesConfig";
import rootReducer from "./mainReducer";
import { changeAuthStatus } from "./appSlice";
import history from "../config/routerHistory";

const expFromToken = () => {
    let token = localStorage.getItem("id_token");
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64)).exp;
}

const unauthorized = (action) => {
  let message = action.error?.message;
  return message == 'Request failed with status code 401';
}

//const authInterceptor = ({getState, dispatch}) => next => action => {
const authInterceptor = store => next => action => {
  next(action);
  //console.group(action.type);
  ////console.log('next state', store.getState().app);
  // console.log('isAuthenticated:  ', store.getState().app.isAuthenticated);
  if(unauthorized(action)) {
    console.log('401 Unauthorized');
    var current_time = Date.now() / 1000;
    if ( current_time > expFromToken()) {
      console.log('Token Expired');
      store.dispatch(changeAuthStatus({isAuthenticated: false}));
      history.push(paths.logout);
    }
  }
  //console.log('  ');
  //console.groupEnd();
}

//const i1 = store => next => action => {
//  //console.group(action.type);
//  console.log('i1 before');
//  next(action);
//  console.log('i1 after');
//  //console.groupEnd();
//}
//const i2 = store => next => action => {
//  //console.group(action.type);
//  console.log('i2 before');
//  next(action);
//  console.log('i2 after');
//  //console.groupEnd();
//}
//const i3 = store => next => action => {
//  //console.group(action.type);
//  console.log('i3 before');
//  next(action);
//  console.log('i3 after');
//  //console.groupEnd();
//}
 

export const store = configureStore({
  reducer: rootReducer,
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authInterceptor, i1, i2, i3),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authInterceptor),
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./mainReducer", () => {
    const newRootReducer = require("./mainReducer").default;
    store.replaceReducer(newRootReducer);
  });
}
