import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchReportsTableData, downloadReportAPI, deleteReportAPI } from "../../../api/reportsAPI";

export const getReportsTableData = createAsyncThunk(
  "reportsTable",
  async (urlParams) => {
    const { reportType, cloud } = urlParams;
    const response = await fetchReportsTableData(reportType, cloud);
    return response;
  }
);

export const downloadReport = createAsyncThunk(
  "reportsDownload",
  async (urlParams) => {
    const { uri } = urlParams;
    const response = await downloadReportAPI(uri);
    return response;
  }
);

export const deleteReport = createAsyncThunk(
  "reportsDelete",
  async (urlParams) => {
    const { keys } = urlParams;
    const response = await deleteReportAPI(keys);
    return response;
  }
);

const reportsTableSlice = createSlice({
  name: "reportsTable",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
    page: 1,
    size: 10,
    refresh: 0,
  },
  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getReportsTableData.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;      
    },
    [getReportsTableData.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    [getReportsTableData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.data = null;
      state.isLoading = false;
    },
  },
});

export const { updateRefresh } = reportsTableSlice.actions;
export default reportsTableSlice.reducer;
