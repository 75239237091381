import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTriageResourceTypeGraphAPI, getTriageAccountsGraphAPI, getTriageRuntimeTopAlertsGraphAPI } from "../../../api/triageAlertsAPI";

// get resource
export const getTriageResourceTypeGraph = createAsyncThunk(
  "triageGraphsResource",
  async (urlParams) => {
    const { cloud, filters } = urlParams;
    const response = await getTriageResourceTypeGraphAPI(cloud, filters);
    return response;
  }
);

// get accounts
export const getTriageAccountsGraph = createAsyncThunk(
  "triageGraphsAccounts",
  async (urlParams) => {
    const { cloud, filters } = urlParams;
    const response = await getTriageAccountsGraphAPI(cloud, filters);
    return response;
  }
);

// get runtime top alerts
export const getTriageRuntimeTopAlertsGraph = createAsyncThunk(
  "triageGraphsTopAlerts",
  async (urlParams) => {
    const { cloud } = urlParams;
    const response = await getTriageRuntimeTopAlertsGraphAPI(cloud);
    return response;
  }
);

const triageGraphsSlice = createSlice({
  name: "triageGraphs",
  initialState: {
    isResourcesLoading: false,
    resourcesError: null,
    resourcesData: null,
    isAccountsLoading: false,
    accountsError: null,
    accountsData: null,
    isTopAlertsLoading: false,
    topAlertsError: null,
    topAlertsData: null,
    refresh: 0,
  },
  reducers: {
    updateRefresh(state) {
      state.refresh++;
    }
  },
  extraReducers: {
    [getTriageResourceTypeGraph.pending]: (state, action) => {
      state.isResourcesLoading = true;
      state.resourcesError = null;
    },
    [getTriageResourceTypeGraph.fulfilled]: (state, action) => {
      state.resourcesData = action.payload;
      state.isResourcesLoading = false;
    },
    [getTriageResourceTypeGraph.rejected]: (state, action) => {
      state.resourcesError = action.error.message;
      state.isResourcesLoading = false;
    },
    [getTriageAccountsGraph.pending]: (state, action) => {
      state.isAccountsLoading = true;
      state.accountsError = null;
    },
    [getTriageAccountsGraph.fulfilled]: (state, action) => {
      state.accountsData = action.payload;
      state.isAccountsLoading = false;
    },
    [getTriageAccountsGraph.rejected]: (state, action) => {
      state.accountsError = action.error.message;
      state.isAccountsLoading = false;
    },
    [getTriageRuntimeTopAlertsGraph.pending]: (state, action) => {
      state.isTopAlertsLoading = true;
      state.topAlertsError = null;
    },
    [getTriageRuntimeTopAlertsGraph.fulfilled]: (state, action) => {
      state.topAlertsData = action.payload;
      state.isTopAlertsLoading = false;
    },
    [getTriageRuntimeTopAlertsGraph.rejected]: (state, action) => {
      state.topAlertsError = action.error.message;
      state.isTopAlertsLoading = false;
    },
  },
});

export const { updateRefresh } = triageGraphsSlice.actions;
export default triageGraphsSlice.reducer;
