import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { blacklist } from "../../api/blacklistAPI";
// ---- http://redux-toolkit.js.org/api/createAsyncThunk

export const blacklistHash = createAsyncThunk(
  "blacklist",
  async (urlParams) => {
    //always 'args', 'thunkAPI' so we bundle our object.
    const{ hash, mode } = urlParams;
    const response = await blacklist(hash, mode);
    return response;
  }
);

const blacklistSlice = createSlice({
  name: "blacklist",
  initialState: {}, // loading is a case-by-case basis, we can't centralize.
  reducers: {
    // no reducers ( standard or fulfilment ) needed
  },
});

export default blacklistSlice.reducer;
