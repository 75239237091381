import axios from 'axios';
import urlMap from "../config/urls";
import { getHeaders } from "./triageAlertsAPI";

export async function blacklist (hash, mode) {
    // let response_result = {};
    // let type = 'BLACKLIST';
    
    let url = urlMap.baseURL + urlMap.blacklistHashUrl;

    const { data } = await axios.get(url, {
    	params: {
    		hash: hash,
    		mode: mode,
    	},
        headers: getHeaders()
    });

    return data;

    // return {
    //     'type': 'BLACKLIST',
    //     'payload': response_result
    // }
}