import axios from "axios";
import { setFromandToDateTime } from "../features/timeframe/timeframeConstants";
import { getParams, getHeaders } from "./triageAlertsAPI"
import urlMap from "../config/urls"

export async function fetchDiscoverTableData(tableType, query, filters, page, size, sortOn, sortType, insight, cloud) {
    filters = filters || {};
    let tabletypeUrls = {
      NETWORK_INFERENCE: urlMap.discoverDataUrl,
      CONN_LOGS: urlMap.connLogsUrl,
      DNS_LOGS: urlMap.dnsLogsUrl,
      SSL_LOGS: urlMap.sslLogsUrl,
      RDP_LOGS: urlMap.rpdLogsUrl,
      RPC_LOGS: urlMap.rpcLogsUrl,
      SMB_LOGS: urlMap.smpLogsUrl,
      SMB_FILE_LOGS: urlMap.smbFileLogsUrl,
      SSH_LOGS: urlMap.sshLogsUrl,
      NTLM_LOGS: urlMap.ntlmLogsUrl,
      DHCP_LOGS: urlMap.dhcpLogsUrl,
      KERBEROS_LOGS: urlMap.kerberosLogsUrl,
      HTTP_LOGS: urlMap.httpLogsUrl,
      CLOUDTRAIL_LOGS: urlMap.cloudtrailLogsUrl,
      AZURE_ACTIVITY_LOGS: urlMap.azureActivityLogsUrl,
    }    
    const url = urlMap.baseURL + tabletypeUrls[tableType]
    const { startDate, endDate } = setFromandToDateTime();
    //creating url with params to handle issue with encoding of square brackets
    query = query || ''; insight = insight || '';
    const fromPage=(page-1) * size
    let paramUri = `?from=${startDate}&interval=${endDate}&fromPage=${fromPage}&size=${size}&sortOn=${sortOn}`+
      `&sortType=${sortType}&query=${encodeURIComponent(query)}&cloud=${cloud}&filters=${encodeURIComponent(JSON.stringify(filters))}`+
      `&insight=${insight}`
    let finalUrl = url + paramUri;
    const { data } = await axios.get(finalUrl, {
      // params: { ...getParams(), query, filters, fromPage, size, sortOn, sortType },
      // paramsSerializer: function(data){
      //   console.log(data);
      //   return qs.stringify(data);
      // },
      headers: getHeaders(),
    });
    return data;
  }
  
  export async function fetchDiscoverAvailableFields() {
    const url = urlMap.baseURL + urlMap.discoverUniqueFieldsUrl
    const { startDate, endDate } = setFromandToDateTime();
    let params = {
      from: startDate,
      interval: endDate,
    }
    const { data } = await axios.get(url, {
      params,
      headers: getHeaders(),
    });
    return data;
  }
  
  export async function fetchDiscoverSavedSearches(){
    const url = urlMap.baseURL + urlMap.discoverSavedSearchesUrl
    const { data } = await axios.get(url, {
      headers: getHeaders(),
    });
    return data;
  }
  
  export async function fetchRelevantInsights(ipAddress, cloud="unknown"){
    const url = urlMap.baseURL + urlMap.discoverRelevantInsightsUrl
    const { startDate, endDate } = setFromandToDateTime();
    let params =  {
      from: startDate,
      interval: endDate,
      ipAddress: ipAddress,
      cloud: cloud,
    };
    const { data } = await axios.get(url, {
      params,
      headers: getHeaders(),
    });
    return data;
  }

  let defaultSaveSuggestionObject = {
    searchType: "network_inference",
    searchQuery: "",
  }

  export async function saveSuggestionAPI(saveSuggestionObject) {
    const url = urlMap.baseURL + urlMap.discoverSaveSuggestionUrl
    let requestBody = {...defaultSaveSuggestionObject,...saveSuggestionObject}
    let response_result = axios.post(url,requestBody,
      {
          headers: getHeaders()
      });
    return response_result;
  }

  export async function getSuggestionsAPI(saveSuggestionObject) {
    const url = urlMap.baseURL + urlMap.discoverSavedSuggestionsUrl
    const { data } = await axios.get(url, {
      headers: getHeaders(),
    });
    return data;
  }
  
  let defaultSaveSearchObject = {
    fromDate : "",
    toDate : "",
    checkedradio : "3",
    queryName : "",
    selectedObjects : [],
    searchQueryParams : "",
    searchType : "network_inference",
    setFilters : {},
    sortColumn : "ts",
    sort : "desc",
  }
  
  export async function saveSearch(saveSearchObject, searchId) {
    const url = urlMap.baseURL + urlMap.discoverSaveSearchUrl
    let response_result = axios.post(url,{
      searchQuery: {...defaultSaveSearchObject,...saveSearchObject}
      },
      {
          headers: getHeaders()
      });
    return response_result;
  }
  
  export async function updateSearch(saveSearchObject, searchId) {
    const url = urlMap.baseURL + urlMap.discoverUpdateSearchUrl
    let response_result = axios.post(url,{
      searchId,
      searchQuery: {...defaultSaveSearchObject,...saveSearchObject}
      },
      {
          headers: getHeaders()
      });
    return response_result;
  }
  
  export async function deleteSearchApi(searchID) {
    const url = urlMap.baseURL + urlMap.discoverDeleteSearchUrl
    let response_result = axios.post(url,{ searchID },
      {
          headers: getHeaders()
      });
    return response_result;
  }
