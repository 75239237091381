import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    userRole: 3, // default to admin
    userRoleName: "",
    isAuthenticated: true
  },
  reducers: {
    changeRole(state, action) {
      const {userRole, userRoleName} = action.payload;
      state.userRole = userRole;
      state.userRoleName = userRoleName;
    },
    changeAuthStatus(state, action) {
      const {isAuthenticated} = action.payload;
      state.isAuthenticated = isAuthenticated;
    },
  },
  extraReducers: {
  },
});

export const { changeRole, changeAuthStatus } = appSlice.actions;
export default appSlice.reducer;
